import ChatIcon from '../../components/icons/navbar/ChatIcon'
import FinanceIcon from '../../components/icons/navbar/FinanceIcon'
import HomeIcon from '../../components/icons/navbar/HomeIcon'
import HumanResourceIcon from '../../components/icons//navbar/HumanResourceIcon'
import ImagesIcon from '../../components/icons/navbar/ImagesIcon'
import MarketingIcon from '../../components/icons/navbar/MarketingIcon'
import SalesIcon from '../../components/icons/navbar/SalesIcon'
import WorkflowIcon from '../../components/icons/navbar/WorkflowIcon'

const arr = [
  {
    id: 1,
    groupName: 'main links',
    navs: [
      {
        id: 11,
        name: 'Home',
        Img: HomeIcon,
        disabled: false,
        url: '',
        quickAccess: false,
        parentRoute: '',
      },

      // {
      //   id: 12,
      //   name: 'Workflows',
      //   Img: WorkflowIcon,
      //   url: 'workflows',
      //   quickAccess: false,
      //   parentRoute: '',
      // },
    ],
  },

  {
    id: 2,
    groupName: 'workflows',
    navs: [
      {
        id: 21,
        name: 'Marketing',
        Img: MarketingIcon,
        disabled: false,
        url: 'marketing',
        quickAccess: true,
        parentRoute: '',
      },

      {
        id: 22,
        name: 'Sales',
        Img: SalesIcon,
        disabled: false,
        url: 'sales',
        quickAccess: true,
        parentRoute: '',
      },

      {
        id: 23,
        name: 'Human Resource',
        Img: HumanResourceIcon,
        disabled: false,
        url: 'hr',
        quickAccess: true,
        parentRoute: '',
      },

      {
        id: 24,
        name: 'Finance',
        Img: FinanceIcon,
        disabled: false,
        url: 'finance',
        quickAccess: true,
        parentRoute: '',
      },
    ],
  },

  {
    id: 3,
    groupName: 'generate',
    navs: [
      {
        id: 31,
        name: 'Chat',
        Img: ChatIcon,
        disabled: false,
        url: 'chat',
        quickAccess: false,
        parentRoute: '',
      },

      {
        id: 32,
        name: 'Images',
        Img: ImagesIcon,
        disabled: false,
        url: 'images',
        quickAccess: false,
        parentRoute: '',
      },
    ],
  },
]

export default arr
