import {FC, useEffect, useState} from 'react'
import navData from '../data/navs/navData'
import {Link, NavLink, useLocation} from 'react-router-dom'
import MenuIcon from './icons/navbar/MenuIcon'
import {useAuth} from '../../app/modules/auth'
import {useLazyServerLogoutQuery} from '../../services/authServiceApi'

const Header: FC = () => {
  const activeNavColor = '#2468FF'
  const standartNavColor = '#616161'
  const headerWideClass = 'headerWide'

  const {currentUser, logout, auth} = useAuth()
  const [serverLogout] = useLazyServerLogoutQuery()
  const currentRoute = useLocation()
  const [headerClass, setHeaderClass] = useState<any>(undefined)
  const [isVisibleProfileMenu, setIsVisibleProfileMenu] = useState<boolean>(false)
  const [isMouseOn, setIsMouseOn] = useState(false)

  const handleHover = (hover = true) => {
    const mouseOn = hover ? true : false
    if (currentRoute.pathname !== '/' && currentRoute.pathname !== '/upgrade-plan') {
      const value = hover ? headerWideClass : undefined
      setIsVisibleProfileMenu(false)
      setHeaderClass(value)
    }
    setIsMouseOn(mouseOn)
  }

  const handleVisibleProfileMenu = (isVisible: boolean = true) => {
    setIsVisibleProfileMenu(isVisible)
  }

  const navBlock = navData.map((item) => {
    const {id: groupId, groupName, navs} = item
    const itemBlock = navs.map((unit) => {
      const {id, name, Img, disabled, url, parentRoute} = unit
      return (
        <li key={id}>
          {!disabled ? (
            <NavLink
              to={parentRoute ? parentRoute + '/' + url : url}
              key={id}
              className='topBlock__nav_ul_li_a'
            >
              {({isActive}) => (
                <>
                  <Img color={isActive ? activeNavColor : standartNavColor} className='svg' />
                  <span>{name}</span>
                </>
              )}
            </NavLink>
          ) : (
            <div className='topBlock__nav_ul_li_a'>
              <Img color={standartNavColor} className='svg' />
              <span>
                {name} <sup className='universalComingSoon'>Coming soon</sup>
              </span>
            </div>
          )}
        </li>
      )
    })
    return (
      <div key={groupId} className='topBlock__nav__group'>
        <h4 className='topBlock__nav__group__title'>{groupName}</h4>
        <ul>{itemBlock}</ul>
      </div>
    )
  })

  useEffect(() => {
    setIsVisibleProfileMenu(false)
    if (currentRoute.pathname === '/' || currentRoute.pathname === '/upgrade-plan') {
      setHeaderClass(headerWideClass)
    } else {
      !isMouseOn && setHeaderClass(undefined)
    }
  }, [currentRoute])

  return (
    <header
      className={`header ${headerClass}`}
      onMouseEnter={() => handleHover()}
      onMouseLeave={() => handleHover(false)}
    >
      <div className='topBlock'>
        <div className='topBlock__logo'>
          <img src='../../new-design/image/logo.png' alt='logo' />
        </div>
        <nav className='topBlock__nav'>{navBlock}</nav>
      </div>
      <div className='bottomBlock'>
        {/* <div className='bottomBlock__refer'>
          <h3 className='bottomBlock__refer__title'>Refer and Earn Credits</h3>
          <span>
            <p>app.klevere.app/ref=1291124...</p>
            <h4>Copy</h4>
          </span>
        </div> */}
        <div
          className='bottomBlock__user'
          onMouseEnter={() => handleVisibleProfileMenu(true)}
          onMouseLeave={() => handleVisibleProfileMenu(false)}
        >
          <div
            className={`bottomBlock__user__profile ${
              isVisibleProfileMenu && 'bottomBlock__user__profileVisible'
            }`}
          >
            <NavLink to='profile' className='bottomBlock__user__profile__navLink'>
              <span>Profile settings</span>
            </NavLink>
            <NavLink to='upgrade-plan' className='bottomBlock__user__profile__navLink'>
              <span>Upgrade</span>
            </NavLink>
            <div
              className='bottomBlock__user__profile__navLink'
              onClick={() => {
                serverLogout(auth?.access_token)
                logout()
              }}
            >
              <span>Log Out</span>
            </div>
          </div>
          <div className='bottomBlock__user__info'>
            <div className='bottomBlock__user__info__imgBlock'>
              <img src={`${currentUser?.avatar || '/new-design/image/avatar.png'}`} alt='user' />
            </div>
            <div className='bottomBlock__user__info__card'>
              <h3 className='bottomBlock__user__info__card__name'>
                {`${currentUser?.first_name} ${currentUser?.last_name}`}
              </h3>
              <address>{currentUser?.email || 'someemail@email.com'}</address>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
