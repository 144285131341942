import {useState, type FC} from 'react'
import classes from './ImagesArea.module.scss'
import RefreshIcon from '../icons/chat/RefreshIcon'
import ImagesContainer from '../universal-components/image-generation/ImagesContainer'

interface ImagesAreaProps {
  images: string[]
  setTriggerRegenerate: Function
  isLoading: boolean
  message: string
  setMessage: Function
  isVisibleMessage: boolean
  handleMessageVisiability: Function
}

const ImagesArea: FC<ImagesAreaProps> = ({
  images,
  setTriggerRegenerate,
  isLoading = false,
  message = '',
  setMessage = () => {},
  isVisibleMessage = false,
  handleMessageVisiability = () => {},
}) => {
  const handleRegenerate = () => {
    setTriggerRegenerate(Date.now())
  }

  return (
    <div className={classes.area}>
      <div className={classes.area__topBlock}>
        <h3 className={classes.area__topBlock__title}>Image Results</h3>
        <p
          className={
            !message.toLowerCase().includes('error')
              ? classes.area__topBlock__successMessage
              : classes.area__topBlock__errorMessage
          }
          style={{display: isVisibleMessage ? 'flex' : 'none'}}
        >
          {message}
        </p>
        <button onClick={handleRegenerate} title='Regenerate' disabled={isLoading}>
          <RefreshIcon className={classes.area__topBlock__svg} />
        </button>
      </div>
      <ImagesContainer
        images={images}
        isLoading={isLoading}
        setMessage={setMessage}
        handleMessageVisiability={handleMessageVisiability}
      />
    </div>
  )
}

export default ImagesArea
