import {useState, type FC} from 'react'
import classes from './PlanPage.module.scss'
import planData from '../../data/plan/planData'
import CheckmarkIcon from '../icons/plan/CheckmarkIcon'

interface PlanPageProps {}

const PlanPage: FC<PlanPageProps> = () => {
  const [isAnnualPlan, setIsAnnualPlan] = useState(false)

  const handleAnnualPlan = (e: any) => {
    const checked = e.target.checked
    setIsAnnualPlan(checked)
  }

  const tableBlock = planData.map((item) => {
    const {
      id,
      title,
      subtitle,
      monthlyPay,
      annualPay,
      urlMonth,
      urlYear,
      usersCount,
      button,
      limits,
      description,
    } = item

    const listBlock = description.map((unit, index) => {
      return (
        <li key={index}>
          <CheckmarkIcon />
          {unit}
        </li>
      )
    })

    return (
      <div key={id} className={classes.plan__table__item}>
        <h2 className={classes.plan__table__item__title}>{title}</h2>
        <h5 className={classes.plan__table__item__subtitle}>{subtitle}</h5>
        <div className={classes.plan__table__item__pay}>
          <span>
            <sup>$</sup>
            {isAnnualPlan ? annualPay : monthlyPay}
          </span>
          {title !== 'Free' ? `/${isAnnualPlan ? 'year' : 'month'}` : null}
        </div>
        <div className={classes.plan__table__item__words}>
          <span>{isAnnualPlan ? limits?.yearly.words : limits?.monthly.words} words</span>
          <span>up to {usersCount} users</span>
        </div>
        <a
          href={isAnnualPlan ? urlYear : urlMonth}
          target='_blank'
          rel='noreferrer'
          className={classes.plan__table__item__btn}
        >
          <button className={`universalButton`}>{button}</button>
        </a>

        <ul>
          {limits ? (
            <>
              <li>
                <CheckmarkIcon />
                {isAnnualPlan ? limits.yearly.credits : limits.monthly.credits}
              </li>
              <li>
                <CheckmarkIcon />
                {isAnnualPlan ? limits.yearly.images : limits.monthly.images}
              </li>
            </>
          ) : null}
          {listBlock}
        </ul>
      </div>
    )
  })

  return (
    <div className={`universalPage ${classes.plan}`}>
      <div className={classes.plan__topBlock}>
        <div className={classes.plan__topBlock__headers}>
          <h4 className={classes.plan__topBlock__headers__title}>Choose your plan</h4>
          <p>Grow at your own pace. Free plan available.</p>
        </div>
        <div className={classes.plan__topBlock__switcher}>
          <h5
            className={`${classes.plan__topBlock__switcher__monthlyTitle} ${
              isAnnualPlan ? classes.plan__topBlock__switcher__passive : undefined
            }`}
          >
            Monthly
          </h5>
          <label className={classes.plan__topBlock__switcher__label}>
            <input
              type='checkbox'
              name='annual'
              checked={isAnnualPlan}
              onChange={handleAnnualPlan}
            />
            <div
              className={`${classes.plan__topBlock__switcher__label__checkbox} ${
                !isAnnualPlan ? classes.plan__topBlock__switcher__label__checkboxPassive : undefined
              } `}
            >
              <div className={classes.plan__topBlock__switcher__label__checkbox__round}></div>
            </div>
            <h5
              className={`${classes.plan__topBlock__switcher__label__annualTitle} ${
                !isAnnualPlan ? classes.plan__topBlock__switcher__passive : undefined
              }`}
            >
              Annual <span>save 20%</span>
            </h5>
          </label>
        </div>
      </div>
      <div className={classes.plan__table}>
        {tableBlock}
        <div className={`${classes.plan__table__item} ${classes.plan__table__itemEmpty}`}>
          <h2 className={classes.plan__table__item__title}>Can’t find a suitable plan?</h2>
          <p>Talk to our sales team and get a custom plan made right for you.</p>
          <a
            href='https://www.klevere.ai/contact-us'
            target='_blank'
            rel='noreferrer'
            className={classes.plan__table__item__btn}
          >
            <button className={`universalButton`}>Contact now</button>
          </a>
        </div>
      </div>
    </div>
  )
}

export default PlanPage
