import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'
const API_URL = process.env.REACT_APP_API_URL

export const companyServiceApi = createApi({
  reducerPath: 'companyServiceApi',
  baseQuery: fetchBaseQuery({baseUrl: `${API_URL}/company`}),
  tagTypes: ['Company'],
  endpoints: (builder) => ({
    getCompany: builder.mutation({
      query: (companyName) => ({
        url: `/limits`,
        method: 'POST',
        body: {companyName},
      }),
      invalidatesTags: ['Company'],
    }),
  }),
})

export const {useGetCompanyMutation} = companyServiceApi
