import {useState, type FC, useEffect} from 'react'
import axios from 'axios'
import classes from './FormArea.module.scss'
import departamentsData from '../../data/chat/departamentData'
import SelectArrowIcon from '../icons/modalform/SelectArrowIcon'
import SendIcon from '../icons/chat/SendIcon'

interface FormAreaProps {
  history: any
  setHistory: Function
  isLoading?: boolean
  setIsLoading?: Function
  triggerRegenerate?: string | number
  D?: string
  question?: string
  departament?: string
  setError: Function
}

interface IInput {
  departament: string
  question: string
}

interface IMessageModel {
  role: string
  content: string
}

const FormArea: FC<FormAreaProps> = ({
  history,
  setHistory,
  isLoading = false,
  setIsLoading = () => {},
  triggerRegenerate = '',
  question,
  departament,
  setError = () => {},
}) => {
  const API_URL = process.env.REACT_APP_GPT_API_URL

  const [input, setInput] = useState<IInput>({
    departament: departamentsData[0].name,
    question: '',
  })
  const handleInput = (e: any) => {
    const name = e.target.name
    const value = e.target.value

    setInput((prev) => ({...prev, [name]: value}))
  }

  const handleForm = (e: any) => {
    e?.type === 'submit' && e.preventDefault()
    setIsLoading(true)
    const questionId = e ? Date.now() : history[history.length - 1].id
    const messagesHistory: IMessageModel[] = []

    history.forEach((item: any) => {
      messagesHistory.push({role: 'user', content: item.question})
      messagesHistory.push({role: 'assistant', content: item.answer})
    })
    e ? messagesHistory.push({role: 'user', content: input.question}) : messagesHistory.pop()

    axios
      .post(`${API_URL}/generate`, {
        department: input.departament,
        message: JSON.stringify(messagesHistory),
      })
      .then((res) => {
        setIsLoading(false)
        setHistory((prev: any) =>
          prev.map((item: any) => {
            return item.id === questionId ? {...item, answer: res.data.result} : item
          })
        )
      })
      .catch((err) => {
        setIsLoading(false)
        setHistory((prev: any) =>
          prev.map((item: any) => {
            return item.id === questionId
              ? {...item, answer: 'Oops!.. Some error occured!.. Try again!'}
              : item
          })
        )
        setError(err.response.data.message || 'Oops!.. Some error occured!.. Try again!')
        console.error('Error', err)
      })
      .finally(() => {
        // setLoading(false)
      })

    if (e) {
      setHistory((prev: any) => [
        ...prev,
        {
          id: questionId,
          question: input.question,
          answer: 'Generating...',
        },
      ])
      return setInput((prev) => ({...prev, question: ''}))
    }

    return setHistory((prev: any) =>
      prev.map((item: any) => {
        return item.id === questionId ? {...item, answer: 'Generating...'} : item
      })
    )
  }

  const optionsBlock = departamentsData.map((item) => {
    const {id, name, title} = item
    return (
      <option key={id} value={name}>
        {title}
      </option>
    )
  })

  useEffect(() => {
    if (triggerRegenerate) {
      handleForm(false)
    }
  }, [triggerRegenerate])

  useEffect(() => {
    if (departament && question) {
      setInput({departament, question})
    }
  }, [departament, question])

  return (
    <form className={classes.form} onSubmit={handleForm}>
      <div className={classes.form__selectBlock}>
        <select
          className={classes.form__selectBlock__select}
          name='departament'
          value={input.departament}
          onChange={handleInput}
        >
          {optionsBlock}
        </select>
        <div className={classes.form__selectBlock__imgBlock}>
          <SelectArrowIcon className={classes.svg} />
        </div>
      </div>
      <div className={classes.form__inputBlock}>
        <input
          type='text'
          name='question'
          value={input.question}
          placeholder='Type here'
          onChange={handleInput}
        />
        <button className={classes.form__inputBlock__btn} disabled={isLoading}>
          <SendIcon className={classes.svg} />
        </button>
      </div>
    </form>
  )
}

export default FormArea
