const membersProfileData = [
  {
    id: 1,
    name: 'John Doe',
    img: '/new-design/image/user.png',
    role: 'Admin',
    dateAdded: new Date('04.07.2021'),
    email: 'Danny123@gmail.com',
    status: 'active',
  },

  {
    id: 2,
    name: 'Jane Doe',
    img: '/new-design/image/user.png',
    role: 'User',
    dateAdded: new Date('04.07.2021'),
    email: 'Danny123@gmail.com',
    status: 'active',
  },

  {
    id: 3,
    name: 'Sarah Doe',
    img: '/new-design/image/user.png',
    role: 'User',
    dateAdded: new Date('04.07.2021'),
    email: 'Danny123@gmail.com',
    status: 'active',
  },
]

export default membersProfileData
