const planData = [
  {
    id: 1,
    title: 'Free',
    subtitle: '7 day trial',
    monthlyPay: 0,
    annualPay: 0,
    urlMonth: '',
    urlYear: '',
    usersCount: 1,
    button: 'Get started free',
    limits: {
      monthly: {words: '10,000', credits: '100 Workflow Credits', images: '10 AI Images'},
      yearly: {words: '10,000', credits: '100 Workflow Credits', images: '10 AI Images'},
    },
    description: ['35+ AI Tools', '15+ Languages', 'Email support'],
  },

  {
    id: 2,
    title: 'Starter',
    subtitle: 'Access to basic features',
    monthlyPay: 29,
    annualPay: 279,
    urlMonth: 'https://buy.stripe.com/3cs7sY0O1awSfXq148',
    urlYear: 'https://buy.stripe.com/fZe4gM1S548u3aEfZ0',
    usersCount: 5,
    button: 'Get started',
    limits: {
      monthly: {words: '50,000', credits: '1,000 Workflow Credits', images: '50 AI Images'},
      yearly: {words: '600,000', credits: '12,000 Workflow Credits', images: '600 AI Images'},
    },
    description: [
      'Automated Workflows',
      'AI strategy chat',
      '35+ AI Tools',
      '15+ Languages',
      'Email + chat support',
      'Integrations',
    ],
  },

  {
    id: 3,
    title: 'Premium',
    subtitle: 'Access to all features',
    monthlyPay: 99,
    annualPay: 940,
    urlMonth: 'https://buy.stripe.com/aEUcNi8gt20mbHa003',
    urlYear: 'https://buy.stripe.com/5kAeVq8gt34qbHa3ch',
    usersCount: 10,
    button: 'Get started',
    limits: {
      monthly: {words: '250,000', credits: '5,000 Workflow Credits', images: '200 AI Images'},
      yearly: {words: '3,000,000', credits: '60,000 Workflow Credits', images: '2400 AI Images'},
    },
    description: [
      'Automated Workflows',
      'AI strategy chat',
      '35+ AI Tools',
      '15+ Languages',
      'Email + chat support',
      'Admin and Role Management',
      'Integrations',
      'AI Voice Command',
    ],
  },
]

export default planData
