import {useState, type FC, useEffect} from 'react'
import classes from './CombinedGeneration.module.scss'
import CopyIcon from '../../icons/chat/CopyIcon'
import MyEditor from '../my-editor/MyEditor'
import DraftsGenerate from '../drafts-generate/DraftsGenerate'
import {AnswerToEdit} from '../../../../types/types'
import RunIcon from '../../icons/workflow/RunIcon'
import CombinedForm from '../combined-form/CombinedForm'
import UniversalHiddenScreen from '../hidden-screen/UniversalHiddenScreen'
import TwitterPreview from '../preview-social/TwitterPreview'
import InstagramPreview from '../preview-social/InstagramPreview'
import FacebookPreview from '../preview-social/FacebookPreview'
import LinkedinPreview from '../preview-social/LinkedinPreview'
import {Link} from 'react-router-dom'
import {useAuth} from '../../../../app/modules/auth'
import {LimitNotification} from '../limit-notification/LimitNotification'
import {toast} from 'react-toastify'

interface CombinedGenerationProps {
  templateItem: any
}

const CombinedGeneration: FC<CombinedGenerationProps> = ({templateItem}) => {
  const standartColor = '#ffff'
  const {currentUser} = useAuth()
  const [currentAnswer, setCurrentAnswer] = useState('....')
  const [answerToEdit, setAnswerToEdit] = useState<AnswerToEdit>({
    id: 0,
    text: '',
  })
  const [previewTemplate, setPreviewTemplate] = useState('')
  const [images, setImages] = useState<string[]>([])
  const [triggerCopyEditor, setTriggerCopyEditor] = useState<number | string>('')
  const [triggerRegenerateText, setTriggerRegenerateText] = useState<number | string>('')
  const [triggerRegenerateImg, setTriggerRegenerateImg] = useState<number | string>('')
  const [disabledButtons, setDisabledButtons] = useState(false)
  const [isLoadingImg, setIsLoadingImg] = useState(false)
  const [isLoadingText, setIsLoadingText] = useState(false)
  const [visibleBackground, setVisibleBackground] = useState(false)
  const [message, setMessage] = useState<string>('')
  const [isVisibleMessage, setIsVisibleMessage] = useState(false)
  const [error, setError] = useState('')

  const contentBlock = {
    Instagram: <InstagramPreview images={images} text={currentAnswer} />,
    Facebook: <FacebookPreview images={images} text={currentAnswer} />,
    LinkedIn: <LinkedinPreview images={images} text={currentAnswer} />,
    Twitter: <TwitterPreview images={images} text={currentAnswer} />,
  }

  const handleMessageVisiability = () => {
    setIsVisibleMessage(true)

    setTimeout(() => {
      setIsVisibleMessage(false)
    }, 2000)
  }

  const handleBackgroundVisibility = () => {
    setVisibleBackground((prev) => !prev)
  }

  const handleTriggerCopyEditor = () => {
    setTriggerCopyEditor(Date.now())
  }

  const handleAnswerToEdit = () => {
    setAnswerToEdit({id: Date.now(), text: currentAnswer})
  }
  const handleCopyAnswer = async (dataToCopy: any) => {
    try {
      if (dataToCopy === '....') {
        toast.warning('No content to copy!')
        return
      }
      if (navigator.clipboard) {
        await navigator.clipboard.writeText(dataToCopy)
      } else {
        const textarea = document.createElement('textarea')
        textarea.value = dataToCopy
        document.body.appendChild(textarea)
        textarea.select()
        document.execCommand('copy')
        document.body.removeChild(textarea)
      }
      toast.success('Content copied successfully')
      handleTriggerCopyEditor()
    } catch (error) {
      console.error('Unable to copy to clipboard', error)
    }
  }
  useEffect(() => {
    if (!isLoadingImg && !isLoadingText) {
      setDisabledButtons(false)
    }
  }, [isLoadingImg, isLoadingText])

  return (
    <div className={`universalPage ${classes.combined}`}>
      {error.trim() !== '' ? <LimitNotification errorMessage={error} /> : null}
      <UniversalHiddenScreen
        visible={visibleBackground}
        handleVisibility={handleBackgroundVisibility}
        contentBlock={contentBlock[previewTemplate as keyof typeof contentBlock]}
      />
      <div className={classes.combined__leftBlock}>
        <div className={`universalTopBlock ${classes.combined__leftBlock__universalTopBlock}`}>
          <h1 className='universalTopTitle'>{templateItem?.title} </h1>
          <div className='universalBreadcrumbs'>
            <span>{templateItem?.header}</span>
            <span>/</span>
            <span className='universalBreadcrumbs__lastChild'>{templateItem?.title}</span>
          </div>
        </div>
        <CombinedForm
          templateItem={templateItem}
          setCurrentAnswer={setCurrentAnswer}
          setIsLoadingImg={setIsLoadingImg}
          setIsLoadingText={setIsLoadingText}
          disabledButtons={disabledButtons}
          setDisabledButtons={setDisabledButtons}
          triggerRegenerateText={triggerRegenerateText}
          triggerRegenerateImg={triggerRegenerateImg}
          setPreviewTemplate={setPreviewTemplate}
          setImages={setImages}
          setMessage={setMessage}
          handleMessageVisiability={handleMessageVisiability}
          setError={setError}
        />
        <div className={classes.combined__leftBlock__drafts}>
          <DraftsGenerate
            currentAnswer={currentAnswer}
            images={images}
            handleAnswerToEdit={handleAnswerToEdit}
            isLoadingImg={isLoadingImg}
            isLoadingText={isLoadingText}
            disabledButtons={disabledButtons}
            setTriggerRegenerateText={setTriggerRegenerateText}
            setTriggerRegenerateImg={setTriggerRegenerateImg}
            message={message}
            setMessage={setMessage}
            isVisibleMessage={isVisibleMessage}
            handleMessageVisiability={handleMessageVisiability}
          />
          <button
            className={`universalButton ${classes.combined__leftBlock__drafts__previewBtn}`}
            style={{display: previewTemplate ? 'flex' : 'none'}}
            disabled={disabledButtons}
            title='Live preview'
            onClick={handleBackgroundVisibility}
          >
            Preview
          </button>
        </div>
      </div>
      <div className={classes.combined__editor}>
        <div className={classes.combined__editor__topBlock}>
          <h3 className={classes.combined__editor__topBlock__title}>Editor</h3>
          <button title='Copy to clipboard' onClick={() => handleCopyAnswer(answerToEdit)}>
            <CopyIcon className={classes.combined__editor__topBlock__svg} />
          </button>
        </div>
        <MyEditor answerToEdit={answerToEdit} triggerCopy={triggerCopyEditor} />
        <Link to='bulk-run'>
          <button
            className={`universalButton ${classes.combined__editor__runBtn}`}
            title='Bulk run'
          >
            <RunIcon className={classes.combined__editor__runBtn__svg} color={standartColor} /> Bulk
            run
          </button>
        </Link>
      </div>
    </div>
  )
}

export default CombinedGeneration
