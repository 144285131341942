import type {FC} from 'react'
import classes from './QuickAccessArea.module.scss'
import quickAccessData from '../../data/homepage/quickAccessData'
import {Link} from 'react-router-dom'

interface QuickAccessAreaProps {}

const QuickAccessArea: FC<QuickAccessAreaProps> = () => {
  const quickAccessBlock = quickAccessData.map((item) => {
    const {id, name, url, Img, disabled, description} = item
    return (
      <div key={id} className={classes.quickAccess__row__item}>
        <h4 className={classes.quickAccess__row__item__title}>
          {name} {disabled ? <sup className='universalComingSoon'>Coming Soon</sup> : ''}
        </h4>
        <span className={classes.quickAccess__row__item__description}>{description}</span>
        <div className={classes.quickAccess__row__item__bottomBlock}>
          {!disabled ? (
            <Link to={url} className={classes.quickAccess__row__item__bottomBlock__btn}>
              Explore
            </Link>
          ) : (
            <span className={classes.quickAccess__row__item__bottomBlock__btn}>Explore</span>
          )}
        </div>
      </div>
    )
  })

  return (
    <div className={classes.quickAccess}>
      <h3 className={classes.quickAccess__title}>Quick Access</h3>
      <div className={classes.quickAccess__row}>{quickAccessBlock}</div>
    </div>
  )
}

export default QuickAccessArea
