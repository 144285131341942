import {SubscriptionPlansEnum, SubscriptionUsersLimitEnum} from '../types/types'

export const getUsersLimit = (name: string) => {
  switch (name) {
    case SubscriptionPlansEnum.STARTER_MONTHLY:
      return SubscriptionUsersLimitEnum.STARTER
    case SubscriptionPlansEnum.PREMIUM_MONTHLY:
      return SubscriptionUsersLimitEnum.PREMIUM
    case SubscriptionPlansEnum.STARTER_YEARLY:
      return SubscriptionUsersLimitEnum.STARTER
    case SubscriptionPlansEnum.PREMIUM_YEARLY:
      return SubscriptionUsersLimitEnum.PREMIUM
    case SubscriptionPlansEnum.FREE:
      return SubscriptionUsersLimitEnum.FREE
    default:
      return 0
  }
}
