import {useState, type FC} from 'react'
import classes from './ProfilePage.module.scss'
import headersProfileData from '../../data/profile/headersProfileData'
import GeneralInfoArea from './GeneralInfoArea'
import BillingArea from './BillingArea'
import MembersArea from './MembersArea'

interface ProfilePageProps {}

const ProfilePage: FC<ProfilePageProps> = () => {
  const activeNavColor = '#2468FF'
  const standartNavColor = '#616161'

  const defaultCell = 'info'

  const body: any = {
    info: <GeneralInfoArea />,
    billing: <BillingArea />,
    members: <MembersArea />,
  }

  const [activeCell, setActiveCell] = useState<string>(
    headersProfileData?.[0].url ? headersProfileData[0].url : defaultCell
  )

  const handleCells = (name: string = defaultCell) => {
    setActiveCell(name)
  }

  const topCellsBlock = headersProfileData?.map((item) => {
    const {id, name, url} = item
    return (
      <div
        key={id}
        className={`${classes.profile__table__top__cell} ${
          activeCell === url ? classes.profile__table__top__cellActive : undefined
        }`}
        onClick={() => handleCells(url)}
      >
        {name}
      </div>
    )
  })

  return (
    <div className={`universalPage ${classes.profile}`}>
      <div className='universalTopBlock'>
        <h1 className='universalTopTitle'>Profile</h1>
        {/* <div className='universalBreadcrumbs'>
          <span>Profile</span>
          <span>/</span>
          <span className='universalBreadcrumbs__lastChild'>Profile</span>
        </div> */}
      </div>
      <div className={classes.profile__table}>
        <div className={classes.profile__table__top}>{topCellsBlock}</div>
        <div className={classes.profile__table__body}>{body[activeCell]}</div>
      </div>
    </div>
  )
}

export default ProfilePage
