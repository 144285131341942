const headersProfileData = [
  {
    id: 1,
    name: 'General information',
    url: 'info',
  },

  {
    id: 2,
    name: 'Billing',
    url: 'billing',
  },

  {
    id: 3,
    name: 'Members',
    url: 'members',
  },
]

export default headersProfileData
