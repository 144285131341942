import OneLineIcon from '../../components/icons/workflow/OneLineIcon'
import ThreeLinesIcon from '../../components/icons/workflow/ThreeLinesIcon'
import toneData from '../universal-data/toneData'

const marketingTemplatesData = {
  workflows: [],
  templates: [
    {
      id: 1,
      header: 'Marketing',
      title: 'Blog article',
      description: 'Captivate audiences with impactful blogs and visuals.',
      url: 'blog-article',
      axiosURL: 'generateBlogArticle',
      axiosHeader: 'blogArticle',
      Img: OneLineIcon,
      formType: 'blog',
      relevanceRequest: false,
      easyRequest: true,
      imgGenerate: true,
      limits: {
        words: 2100,
      },
      requiredQuestions: [
        {
          id: 11,
          title: 'What is the topic of your blog?',
          type: 'input',
          placeholder: 'Enter...',
          axiosParamName: 'title',
          tooltipText:
            'Every blog should have a clear and concise topic. Be clear on what you want your audience to learn',
          tooltipAnchor: 'topic_mark',
        },
        {
          id: 12,
          title: 'Primary keywords',
          type: 'input',
          placeholder: 'SEO keywords..',
          axiosParamName: 'keywords',
        },
      ],
      
      additionalQuestions: [
        {
          id: 13,
          title: 'What is the purpose or goal of your blog?',
          type: 'input',
          placeholder: 'Enter...',
          axiosParamName: 'goal',
          tooltipAnchor: 'goal_mark',
          tooltipText:
            'Are you aiming to educate your audience, showcase your expertise, drive website traffic, generate leads, or promote a specific product or service?',
        },
        {
          id: 14,
          title: 'Who is your target audience?',
          type: 'input',
          placeholder: 'Enter...',
          axiosParamName: 'audience',
          tooltipAnchor: 'audience_mark',
          tooltipText:
            'Who are you trying to reach? What are their demographics, interests, and pain points?',
        },

        {
          id: 15,
          title: 'What action should your audience take?',
          type: 'input',
          placeholder: 'Enter...',
          axiosParamName: 'action',
          tooltipAnchor: 'action_mark',
          tooltipText:
            'Define the desired action (e.g., sign up to your blog, make a purchase, request a demo)',
        },
      ],
      optionalSelect: [
        {
          id: 16,
          title: 'Tone',
          options: [...toneData],
          axiosParamName: 'tone',
        },
      ],
    },
    {
      id: 45,
      header: 'Marketing',
      title: '[GPT-4o] Blog article',
      description: 'Captivate audiences with impactful blogs and visuals.',
      url: 'blog-article-gpt4o',
      axiosURL: 'generateBlogArticleGpt4o',
      axiosHeader: 'blogArticle',
      Img: OneLineIcon,
      formType: 'blog',
      relevanceRequest: false,
      easyRequest: true,
      imgGenerate: true,
      limits: {
        words: 2100,
      },
      requiredQuestions: [
        {
          id: 11,
          title: 'What is the topic of your blog?',
          type: 'input',
          placeholder: 'Enter...',
          axiosParamName: 'title',
          tooltipText:
            'Every blog should have a clear and concise topic. Be clear on what you want your audience to learn',
          tooltipAnchor: 'topic_mark',
        },
        {
          id: 12,
          title: 'Primary keywords',
          type: 'input',
          placeholder: 'SEO keywords..',
          axiosParamName: 'keywords',
        },
      ],
      
      additionalQuestions: [
        {
          id: 13,
          title: 'What is the purpose or goal of your blog?',
          type: 'input',
          placeholder: 'Enter...',
          axiosParamName: 'goal',
          tooltipAnchor: 'goal_mark',
          tooltipText:
            'Are you aiming to educate your audience, showcase your expertise, drive website traffic, generate leads, or promote a specific product or service?',
        },
        {
          id: 14,
          title: 'Who is your target audience?',
          type: 'input',
          placeholder: 'Enter...',
          axiosParamName: 'audience',
          tooltipAnchor: 'audience_mark',
          tooltipText:
            'Who are you trying to reach? What are their demographics, interests, and pain points?',
        },

        {
          id: 15,
          title: 'What action should your audience take?',
          type: 'input',
          placeholder: 'Enter...',
          axiosParamName: 'action',
          tooltipAnchor: 'action_mark',
          tooltipText:
            'Define the desired action (e.g., sign up to your blog, make a purchase, request a demo)',
        },
      ],
      optionalSelect: [
        {
          id: 16,
          title: 'Tone',
          options: [...toneData],
          axiosParamName: 'tone',
        },
      ],
    },

    {
      id: 2,
      header: 'Marketing',
      title: 'Website headline',
      description: 'Let your website stand out with powerful headlines.',
      url: 'website-headline',
      axiosURL: 'generateWebHeadline',
      axiosHeader: 'headline',
      Img: OneLineIcon,
      formType: 'regular',
      relevanceRequest: false,
      easyRequest: true,
      imgGenerate: false,
      limits: {
        words: 170,
      },
      requiredQuestions: [
        {
          id: 21,
          title: 'What does your company do?',
          type: 'input',
          placeholder: 'Enter...',
          axiosParamName: 'product',
          tooltipAnchor: 'product_mark',
          tooltipText:
            'What is the nature of your business? What products or services do you offer?',

        },

        {
          id: 22,
          title: 'What are your unique selling points? ',
          type: 'input',
          placeholder: 'Enter...',
          axiosParamName: 'points',
          tooltipAnchor: 'points_mark',
          tooltipText:
            'What makes your product or service stand out in the market? What benefits or features differentiate you from competitors?',
        },
      ],
      additionalQuestions: [
        {
          id: 23,
          title: 'Who is your target audience?',
          type: 'input',
          placeholder: 'Enter...',
          axiosParamName: 'audience',
          tooltipText:
            'Who are you trying to reach? What are their demographics, interests, and pain points?',
          tooltipAnchor: 'audience_mark',
        },
      ],
      optionalSelect: [
        {
          id: 24,
          title: 'Tone',
          options: [...toneData],
          axiosParamName: 'tone',
        },
      ],
    },
    {
      id: 222,
      header: 'Marketing',
      title: '[GPT-4o] Website headline',
      description: 'Let your website stand out with powerful headlines.',
      url: 'website-headline-gpt-4o',
      axiosURL: 'generateWebHeadlineGpt4o',
      axiosHeader: 'headline',
      Img: OneLineIcon,
      formType: 'regular',
      relevanceRequest: false,
      easyRequest: true,
      imgGenerate: false,
      limits: {
        words: 170,
      },
      requiredQuestions: [
        {
          id: 21,
          title: 'What does your company do?',
          type: 'input',
          placeholder: 'Enter...',
          axiosParamName: 'product',
          tooltipAnchor: 'product_mark',
          tooltipText:
            'What is the nature of your business? What products or services do you offer?',

        },

        {
          id: 22,
          title: 'What are your unique selling points? ',
          type: 'input',
          placeholder: 'Enter...',
          axiosParamName: 'points',
          tooltipAnchor: 'points_mark',
          tooltipText:
            'What makes your product or service stand out in the market? What benefits or features differentiate you from competitors?',
        },
      ],
      additionalQuestions: [
        {
          id: 23,
          title: 'Who is your target audience?',
          type: 'input',
          placeholder: 'Enter...',
          axiosParamName: 'audience',
          tooltipText:
            'Who are you trying to reach? What are their demographics, interests, and pain points?',
          tooltipAnchor: 'audience_mark',
        },
      ],
      optionalSelect: [
        {
          id: 24,
          title: 'Tone',
          options: [...toneData],
          axiosParamName: 'tone',
        },
      ],
    },

    {
      id: 3,
      header: 'Marketing',
      title: 'Digital advertising',
      description: 'Generate ad content that improves conversion rates.',
      url: 'advertising',
      axiosURL: 'generateDigitalAd',
      axiosHeader: 'digitalAd',
      Img: OneLineIcon,
      formType: 'blog',
      relevanceRequest: false,
      easyRequest: true,
      imgGenerate: false,
      limits: {
        words: 30,
      },
      requiredQuestions: [
        {
          id: 31,
          title: 'What does your company do?',
          type: 'input',
          placeholder: 'Enter...',
          axiosParamName: 'product',
          tooltipAnchor: 'topic_mark',
          tooltipText:
            'What is the nature of your business? What products or services do you offer?',
        },

        {
          id: 32,
          title: 'What are your unique selling points?',
          type: 'input',
          placeholder: 'Enter...',
          axiosParamName: 'points',
          tooltipAnchor: 'points_mark',
          tooltipText:
            'What makes your product or service stand out in the market? What benefits or features differentiate you from competitors?',
        },
      ],
      additionalQuestions: [
        {
          id: 33,
          title: 'Who is your target audience?',
          type: 'input',
          placeholder: 'Enter...',
          axiosParamName: 'audience',
          tooltipAnchor: 'audience_mark',
          tooltipText:
            'Who are you trying to reach? What are their demographics, interests, and pain points?',
        },
        {
          id: 34,
          title: 'What action should your audience take?',
          type: 'input',
          placeholder: 'Enter...',
          axiosParamName: 'action',
          tooltipAnchor: 'action_mark',
          tooltipText:
            'Define the desired action (e.g., sign up to your blog, make a purchase, request a demo)',
        },
      ],
      optionalSelect: [
        {
          id: 35,
          title: 'Tone',
          options: [...toneData],
          axiosParamName: 'tone',
        },
      ],
    },

    {
      id: 333,
      header: 'Marketing',
      title: '[GPT-4o] Digital advertising',
      description: 'Generate ad content that improves conversion rates.',
      url: 'advertising-gpt-4o',
      axiosURL: 'generateDigitalAdGpt4o',
      axiosHeader: 'digitalAd',
      Img: OneLineIcon,
      formType: 'blog',
      relevanceRequest: false,
      easyRequest: true,
      imgGenerate: false,
      limits: {
        words: 30,
      },
      requiredQuestions: [
        {
          id: 31,
          title: 'What does your company do?',
          type: 'input',
          placeholder: 'Enter...',
          axiosParamName: 'product',
          tooltipAnchor: 'topic_mark',
          tooltipText:
            'What is the nature of your business? What products or services do you offer?',
        },

        {
          id: 32,
          title: 'What are your unique selling points?',
          type: 'input',
          placeholder: 'Enter...',
          axiosParamName: 'points',
          tooltipAnchor: 'points_mark',
          tooltipText:
            'What makes your product or service stand out in the market? What benefits or features differentiate you from competitors?',
        },
      ],
      additionalQuestions: [
        {
          id: 33,
          title: 'Who is your target audience?',
          type: 'input',
          placeholder: 'Enter...',
          axiosParamName: 'audience',
          tooltipAnchor: 'audience_mark',
          tooltipText:
            'Who are you trying to reach? What are their demographics, interests, and pain points?',
        },
        {
          id: 34,
          title: 'What action should your audience take?',
          type: 'input',
          placeholder: 'Enter...',
          axiosParamName: 'action',
          tooltipAnchor: 'action_mark',
          tooltipText:
            'Define the desired action (e.g., sign up to your blog, make a purchase, request a demo)',
        },
      ],
      optionalSelect: [
        {
          id: 35,
          title: 'Tone',
          options: [...toneData],
          axiosParamName: 'tone',
        },
      ],
    },

    {
      id: 4,
      header: 'Marketing',
      title: 'Product descriptions',
      description: 'Create descriptions that motivate buyers to click.',
      url: 'prod-description',
      axiosURL: 'generateProductDescription',
      axiosHeader: 'productDescription',
      Img: OneLineIcon,
      formType: 'blog',
      relevanceRequest: false,
      easyRequest: true,
      imgGenerate: false,
      limits: {
        words: 53,
      },
      requiredQuestions: [
        {
          id: 41,
          title: 'What does your product do? ',
          type: 'input',
          placeholder: 'Enter...',
          axiosParamName: 'product',
          tooltipAnchor: 'product_mark',
          tooltipText: 'What is the nature of your product? What problem does it solve?',
        },

        {
          id: 42,
          title: 'What are your unique selling points?',
          type: 'input',
          placeholder: 'Enter...',
          axiosParamName: 'points',
          tooltipAnchor: 'points_mark',
          tooltipText:
            'What makes your product or service stand out in the market? What benefits or features differentiate you from competitors?',
        },
      ],
      additionalQuestions: [
        {
          id: 43,
          title: 'Who is your target audience?',
          type: 'input',
          placeholder: 'Enter...',
          axiosParamName: 'audience',
          tooltipAnchor: 'audience_mark',
          tooltipText:
            'Who are you trying to reach? What are their demographics, interests, and pain points?',
        },
      ],
      optionalSelect: [
        {
          id: 44,
          title: 'Tone',
          options: [...toneData],
          axiosParamName: 'tone',
        },
      ],
    },
    {
      id: 444,
      header: 'Marketing',
      title: '[GPT-4o] Product descriptions',
      description: 'Create descriptions that motivate buyers to click.',
      url: 'prod-description-gpt-4o',
      axiosURL: 'generateProductDescriptionGpt4o',
      axiosHeader: 'productDescription',
      Img: OneLineIcon,
      formType: 'blog',
      relevanceRequest: false,
      easyRequest: true,
      imgGenerate: false,
      limits: {
        words: 53,
      },
      requiredQuestions: [
        {
          id: 41,
          title: 'What does your product do? ',
          type: 'input',
          placeholder: 'Enter...',
          axiosParamName: 'product',
          tooltipAnchor: 'product_mark',
          tooltipText: 'What is the nature of your product? What problem does it solve?',
        },

        {
          id: 42,
          title: 'What are your unique selling points?',
          type: 'input',
          placeholder: 'Enter...',
          axiosParamName: 'points',
          tooltipAnchor: 'points_mark',
          tooltipText:
            'What makes your product or service stand out in the market? What benefits or features differentiate you from competitors?',
        },
      ],
      additionalQuestions: [
        {
          id: 43,
          title: 'Who is your target audience?',
          type: 'input',
          placeholder: 'Enter...',
          axiosParamName: 'audience',
          tooltipAnchor: 'audience_mark',
          tooltipText:
            'Who are you trying to reach? What are their demographics, interests, and pain points?',
        },
      ],
      optionalSelect: [
        {
          id: 44,
          title: 'Tone',
          options: [...toneData],
          axiosParamName: 'tone',
        },
      ],
    },
    // {
    //   id: 5,
    //   header: 'Marketing',
    //   title: 'Landing page',
    //   description: 'Improve first impressions and drive higher conversions.',
    //   url: 'landing',
    //   axiosURL: 'generateLandingPageContent',
    //   axiosHeader: 'landingPageContent',
    //   Img: OneLineIcon,
    //   formType: 'blog',
    //   requiredQuestions: [
    //     {
    //       id: 51,
    //       title: 'What does your company do?',
    //       type: 'input',
    //       placeholder: 'Enter...',
    //       axiosParamName: 'product',
    //     },

    //     {
    //       id: 52,
    //       title: 'What are your unique selling points?',
    //       type: 'input',
    //       axiosParamName: 'points',
    //     },
    //   ],
    //   additionalQuestions: [
    //     {
    //       id: 53,
    //       title: 'Who is your target audience?',
    //       type: 'input',
    //       placeholder: 'Enter...',
    //       axiosParamName: 'audience',
    //     },
    //     {
    //       id: 54,
    //       title: 'What action should your audience take?',
    //       type: 'input',
    //       placeholder: 'Enter...',
    //       axiosParamName: 'action',
    //     },
    //   ],
    //   optionalSelect: [
    //     {
    //       id: 1,
    //       title: 'Tone',
    //       options: [...toneData],
    //       axiosParamName: 'tone',
    //     },
    //   ],
    // },
    {
      id: 6,
      header: 'Marketing',
      title: 'Social Media Post',
      description: 'Generate captivating content for audiences across social channels.',
      url: 'social',
      axiosURL: 'generateSocialMediaPost',
      axiosHeader: 'socialMedia',
      Img: OneLineIcon,
      formType: 'social',
      relevanceRequest: false,
      easyRequest: true,
      imgGenerate: true,
      limits: {
        words: 220,
      },
      requiredQuestions: [
        {
          id: 61,
          title: 'What is the topic of your post? ',
          type: 'input',
          placeholder: 'Enter...',
          axiosParamName: 'topic',
          tooltipAnchor: 'topic_mark',
          tooltipText:
            'Every post should have a clear and concise topic. Be clear on what you want your audience to learn',
        },

        {
          id: 62,
          title: 'What is the goal of your social media post?',
          type: 'input',
          placeholder: 'Enter...',
          axiosParamName: 'goal',
          tooltipAnchor: 'topic_desccription',
          tooltipText:
            'Are you aiming for brand awareness, engagement, website traffic, lead generation, sales, or something else?',
        },
      ],
      additionalQuestions: [
        {
          id: 63,
          title: 'Who is your target audience?',
          type: 'input',
          placeholder: 'Enter...',
          axiosParamName: 'audience',
          tooltipAnchor: 'audience_mark',
          tooltipText:
            'Who are you trying to reach? What are their demographics, interests, and pain points?',
        },
      ],
      optionalSelect: [
        {
          id: 64,
          title: 'Tone',
          options: [...toneData],
          axiosParamName: 'tone',
        },
      ],
    },
    {
      id: 66,
      header: 'Marketing',
      title: '[GPT-4o] Social Media Post',
      description: 'Generate captivating content for audiences across social channels.',
      url: 'social-gpt-4o',
      axiosURL: 'generateSocialMediaPostGpt4o',
      axiosHeader: 'socialMedia',
      Img: OneLineIcon,
      formType: 'social',
      relevanceRequest: false,
      easyRequest: true,
      imgGenerate: true,
      limits: {
        words: 220,
      },
      requiredQuestions: [
        {
          id: 61,
          title: 'What is the topic of your post? ',
          type: 'input',
          placeholder: 'Enter...',
          axiosParamName: 'topic',
          tooltipAnchor: 'topic_mark',
          tooltipText:
            'Every post should have a clear and concise topic. Be clear on what you want your audience to learn',
        },

        {
          id: 62,
          title: 'What is the goal of your social media post?',
          type: 'input',
          placeholder: 'Enter...',
          axiosParamName: 'goal',
          tooltipAnchor: 'topic_desccription',
          tooltipText:
            'Are you aiming for brand awareness, engagement, website traffic, lead generation, sales, or something else?',
        },
      ],
      additionalQuestions: [
        {
          id: 63,
          title: 'Who is your target audience?',
          type: 'input',
          placeholder: 'Enter...',
          axiosParamName: 'audience',
          tooltipAnchor: 'audience_mark',
          tooltipText:
            'Who are you trying to reach? What are their demographics, interests, and pain points?',
        },
      ],
      optionalSelect: [
        {
          id: 64,
          title: 'Tone',
          options: [...toneData],
          axiosParamName: 'tone',
        },
      ],
    },
    {
      id: 7,
      header: 'Marketing',
      title: 'Newsletter',
      description: 'Engage audiences with thought-provoking newsletters.',
      url: 'newsletter',
      axiosURL: 'generateNewsletterPost',
      axiosHeader: 'newsLetter',
      Img: OneLineIcon,
      formType: 'regular',
      relevanceRequest: false,
      easyRequest: true,
      imgGenerate: false,
      limits: {
        words: 1620,
      },
      requiredQuestions: [
        {
          id: 71,
          title: 'What is the topic of your newsletter?',
          type: 'input',
          placeholder: 'Enter...',
          axiosParamName: 'topic',
          tooltipAnchor: 'topic_mark',
          tooltipText:
            'Every newsletter should have a clear and concise topic. Be clear on what you want your audience to learn',
        },
        {
          id: 72,
          title: 'Primary keywords',
          type: 'input',
          placeholder: 'SEO keywords..',
          axiosParamName: 'keywords',
        },

        // {
        //   id: 72,
        //   title: 'What is the purpose or goal of your newsletter?',
        //   type: 'input',
        //   placeholder: 'Enter...',
        //   axiosParamName: 'goal',
        // },
      ],
      additionalQuestions: [
        {
          id: 73,
          title: 'Who is your target audience?',
          type: 'input',
          placeholder: 'Enter...',
          axiosParamName: 'audience',
          tooltipAnchor: 'audience_mark',
          tooltipText:
            'Who are you trying to reach? What are their demographics, interests, and pain points?',
        },
        {
          id: 74,
          title: 'What action should your audience take?',
          type: 'input',
          placeholder: 'Enter...',
          axiosParamName: 'action',
          tooltipAnchor: 'action_mark',
          tooltipText:
            'Define the desired action (e.g., sign up to a webinar, make a purchase, request a demo)',
        },
      ],
      optionalSelect: [
        {
          id: 75,
          title: 'Tone',
          options: [...toneData],
          axiosParamName: 'tone',
        },
      ],
    },
    {
      id: 7,
      header: 'Marketing',
      title: '[GPT-4o] Newsletter',
      description: 'Engage audiences with thought-provoking newsletters.',
      url: 'newsletter-gpt-4o',
      axiosURL: 'generateNewsletterPostGpt4o',
      axiosHeader: 'newsLetter',
      Img: OneLineIcon,
      formType: 'regular',
      relevanceRequest: false,
      easyRequest: true,
      imgGenerate: false,
      limits: {
        words: 1620,
      },
      requiredQuestions: [
        {
          id: 71,
          title: 'What is the topic of your newsletter?',
          type: 'input',
          placeholder: 'Enter...',
          axiosParamName: 'topic',
          tooltipAnchor: 'topic_mark',
          tooltipText:
            'Every newsletter should have a clear and concise topic. Be clear on what you want your audience to learn',
        },
        {
          id: 72,
          title: 'Primary keywords',
          type: 'input',
          placeholder: 'SEO keywords..',
          axiosParamName: 'keywords',
        },

        // {
        //   id: 72,
        //   title: 'What is the purpose or goal of your newsletter?',
        //   type: 'input',
        //   placeholder: 'Enter...',
        //   axiosParamName: 'goal',
        // },
      ],
      additionalQuestions: [
        {
          id: 73,
          title: 'Who is your target audience?',
          type: 'input',
          placeholder: 'Enter...',
          axiosParamName: 'audience',
          tooltipAnchor: 'audience_mark',
          tooltipText:
            'Who are you trying to reach? What are their demographics, interests, and pain points?',
        },
        {
          id: 74,
          title: 'What action should your audience take?',
          type: 'input',
          placeholder: 'Enter...',
          axiosParamName: 'action',
          tooltipAnchor: 'action_mark',
          tooltipText:
            'Define the desired action (e.g., sign up to a webinar, make a purchase, request a demo)',
        },
      ],
      optionalSelect: [
        {
          id: 75,
          title: 'Tone',
          options: [...toneData],
          axiosParamName: 'tone',
        },
      ],
    },
    {
      id: 8,
      header: 'Marketing',
      title: 'Press release',
      description: 'Publicise activity and generate rapid media traction.',
      url: 'press-release',
      axiosURL: 'generatePressRelease',
      axiosHeader: 'pressRelease',
      Img: OneLineIcon,
      formType: 'regular',
      relevanceRequest: false,
      easyRequest: true,
      imgGenerate: false,
      limits: {
        words: 1440,
      },
      requiredQuestions: [
        {
          id: 81,
          title: 'What is the topic of your press release? ',
          type: 'input',
          placeholder: 'Enter...',
          axiosParamName: 'topic',
          tooltipAnchor: 'topic_mark',
          tooltipText:
            'Every press release should have a clear and concise topic. Be clear on what you want your audience to learn',
        },
        {
          id: 82,
          title: 'Primary keywords',
          type: 'input',
          placeholder: 'SEO keywords..',
          axiosParamName: 'keywords',
        },

        // {
        //   id: 83,
        //   title: 'What is the purpose or goal of your press release?',
        //   type: 'input',
        //   placeholder: 'Enter...',
        //   axiosParamName: 'goal',
        // },
      ],
      additionalQuestions: [
        {
          id: 83,
          title: 'Who is your target audience?',
          type: 'input',
          placeholder: 'Enter...',
          axiosParamName: 'audience',
          tooltipAnchor: 'audince_mark',
          tooltipText:
            'Who are you trying to reach? What are their demographics, interests, and pain points?',
        },
      ],
      optionalSelect: [
        {
          id: 84,
          title: 'Tone',
          options: [...toneData],
          axiosParamName: 'tone',
        },
      ],
    },
    {
      id: 8,
      header: 'Marketing',
      title: '[GPT-4o] Press release',
      description: 'Publicise activity and generate rapid media traction.',
      url: 'press-release-gpt-4o',
      axiosURL: 'generatePressReleaseGpt4o',
      axiosHeader: 'pressRelease',
      Img: OneLineIcon,
      formType: 'regular',
      relevanceRequest: false,
      easyRequest: true,
      imgGenerate: false,
      limits: {
        words: 1440,
      },
      requiredQuestions: [
        {
          id: 81,
          title: 'What is the topic of your press release? ',
          type: 'input',
          placeholder: 'Enter...',
          axiosParamName: 'topic',
          tooltipAnchor: 'topic_mark',
          tooltipText:
            'Every press release should have a clear and concise topic. Be clear on what you want your audience to learn',
        },
        {
          id: 82,
          title: 'Primary keywords',
          type: 'input',
          placeholder: 'SEO keywords..',
          axiosParamName: 'keywords',
        },

        // {
        //   id: 83,
        //   title: 'What is the purpose or goal of your press release?',
        //   type: 'input',
        //   placeholder: 'Enter...',
        //   axiosParamName: 'goal',
        // },
      ],
      additionalQuestions: [
        {
          id: 83,
          title: 'Who is your target audience?',
          type: 'input',
          placeholder: 'Enter...',
          axiosParamName: 'audience',
          tooltipAnchor: 'audince_mark',
          tooltipText:
            'Who are you trying to reach? What are their demographics, interests, and pain points?',
        },
      ],
      optionalSelect: [
        {
          id: 84,
          title: 'Tone',
          options: [...toneData],
          axiosParamName: 'tone',
        },
      ],
    },

    {
      id: 9,
      header: 'Marketing',
      title: 'Image to Social Media Caption',
      description: 'Upload any image to create powerful social media captions in seconds',
      url: 'media-caption',
      axiosURL: 'generateVisualSocialMediaCaption',
      axiosHeader: 'mediaCaption',
      Img: OneLineIcon,
      formType: 'regular',
      relevanceRequest: false,
      easyRequest: true,
      imgGenerate: false,
      limits: {
        credits: 4,
      },
      requiredQuestions: [
        {
          id: 91,
          title: 'Upload Image? ',
          type: 'upload',
          placeholder: '+ Upload Image',
          axiosParamName: 'url',
        },
      ],
      additionalQuestions: [],
      optionalSelect: [],
    },

    {
      id: 10,
      header: 'Marketing',
      title: 'Image to Product Description',
      description: 'Upload any image to create powerful product description in seconds.',
      url: 'visual-prod-description',
      axiosURL: 'generateVisualProductDescription',
      axiosHeader: 'visualProductDescription',
      Img: OneLineIcon,
      formType: 'regular',
      relevanceRequest: false,
      easyRequest: true,
      imgGenerate: false,
      limits: {
        credits: 4,
      },
      requiredQuestions: [
        {
          id: 101,
          title: 'Upload Image? ',
          type: 'upload',
          placeholder: '+ Upload Image',
          axiosParamName: 'url',
        },
      ],
      additionalQuestions: [],
      optionalSelect: [],
    },

    {
      id: 11,
      header: 'Marketing',
      title: 'Compare Webpages',
      description: 'Analyze and contrast two webpages to gain valuable insights.',
      Img: ThreeLinesIcon,
      url: 'compare-webpages',
      axiosURL: '444c6b6f-42b4-4166-a356-4050fdc1658c',
      axiosHeader: '',
      easyRequest: false,
      relevanceRequest: true,
      imgGenerate: false,
      limits: {
        words: 200,
        credits: 14,
      },
      requiredQuestions: [
        {
          id: 111,
          title: 'Enter website A',
          type: 'input',
          placeholder: 'Link A..',
          axiosParamName: 'webpage_a',
          tooltipAnchor: 'webpageA_mark',
          tooltipText: 'Webpage A URL; that you wish to analyze and compare with webpage B.',
        },
        {
          id: 112,
          title: 'Enter website B',
          type: 'input',
          placeholder: 'Link B..',
          axiosParamName: 'webpage_b',
          tooltipAnchor: 'webpageA_mark',
          tooltipText: 'Webpage A URL; that you wish to analyze and compare with webpage B.',
        },
        {
          id: 113,
          title: "What's the objective of your comparison?",
          type: 'input',
          placeholder: 'Your goal..',
          axiosParamName: 'goal',
          tooltipAnchor: 'goal_mark',
          tooltipText: "What's the objective of your comparison?",
        },
      ],
      additionalQuestions: [],
      optionalSelect: [],
    },

    {
      id: 12,
      header: 'Marketing',
      title: 'Youtube Video to Blog',
      description: 'Generate full length blog articles based on Youtube video URL’s.',
      Img: ThreeLinesIcon,
      url: 'youtube',
      axiosURL: '3732e21a-672f-4b95-9e8b-d34bc3911f64',
      axiosHeader: '',
      relevanceRequest: true,
      easyRequest: false,
      imgGenerate: false,
      limits: {
        words: 250,
        credits: 6,
      },
      requiredQuestions: [
        {
          id: 121,
          title: 'Enter YouTube video URL',
          type: 'input',
          placeholder: 'Link to YouTube..',
          axiosParamName: 'video_url',
        },

        {
          id: 122,
          title: 'Enter Blog name',
          type: 'input',
          placeholder: 'Blog name..',
          axiosParamName: 'blog_name',
        },
      ],
      additionalQuestions: [
        {
          id: 123,
          title: 'Add some SEO keywords',
          type: 'input',
          placeholder: 'SEO keywords..',
          axiosParamName: 'keywords',
        },
      ],
      optionalSelect: [],
    },

    {
      id: 13,
      header: 'Marketing',
      title: 'Feedback to Webpage',
      description: 'Get comprehensive feedback on your webpage or landing page.',
      Img: ThreeLinesIcon,
      url: 'webpage-feedback',
      axiosURL: 'generateWebsiteFeedback',
      axiosHeader: 'websiteFeedback',
      relevanceRequest: true,
      easyRequest: true,
      imgGenerate: false,
      limits: {
        words: 200,
        credits: 9,
      },
      requiredQuestions: [
        {
          id: 131,
          title: 'Enter Webpage URL',
          type: 'input',
          placeholder: 'Link..',
          axiosParamName: 'website',
          tooltipAnchor: 'url_mark',
          tooltipText: "The URL of the webpage you're seeking actionable feedback on.",
        },

        {
          id: 132,
          title: 'Area of focus',
          type: 'input',
          placeholder: 'Context...',
          axiosParamName: 'goal',
          tooltipAnchor: 'point_mark',
          tooltipText: 'E.g. full page',
        },
      ],
      additionalQuestions: [],
      optionalSelect: [],
    },

    // Custom workflows
    {
      id: 14,
      header: 'Marketing',
      title: 'C* Image to Social Media Caption',
      description: 'Upload any image to create powerful social media captions in seconds',
      url: 'media-caption-custom',
      axiosURL: 'generateVisualSocialMediaCaptionC',
      axiosHeader: 'mediaPost',
      Img: OneLineIcon,
      formType: 'regular',
      relevanceRequest: false,
      easyRequest: true,
      imgGenerate: false,
      limits: {
        credits: 4,
      },
      requiredQuestions: [
        {
          id: 132,
          title: 'Upload Image? ',
          type: 'upload',
          placeholder: '+ Upload Image',
          axiosParamName: 'url',
        },

        {
          id: 133,
          title: 'Keywords',
          type: 'input',
          placeholder: 'Enter Keywords',
          axiosParamName: 'key_words',
          tooltipAnchor: 'url_mark',
          tooltipText: "Keywords you'd like Klevere to focus on in your caption",
        },
      ],
      additionalQuestions: [],
      optionalSelect: [
        {
          id: 134,
          title: 'Length',
          options: ['long', 'short'],
          axiosParamName: 'length',
        },
      ],
    },

    {
      id: 144,
      header: 'Marketing',
      title: '[GPT-4o] Image to Social Media Caption',
      description: 'Upload any image to create powerful social media captions in seconds',
      url: 'media-caption-custom-gpt-4o',
      axiosURL: 'generateVisualSocialMediaCaptionGpt4o',
      axiosHeader: 'mediaPost',
      Img: OneLineIcon,
      formType: 'regular',
      relevanceRequest: false,
      easyRequest: true,
      imgGenerate: false,
      limits: {
        credits: 4,
      },
      requiredQuestions: [
        {
          id: 132,
          title: 'Upload Image? ',
          type: 'upload',
          placeholder: '+ Upload Image',
          axiosParamName: 'url',
        },

        {
          id: 133,
          title: 'Keywords',
          type: 'input',
          placeholder: 'Enter Keywords',
          axiosParamName: 'key_words',
          tooltipAnchor: 'url_mark',
          tooltipText: "Keywords you'd like Klevere to focus on in your caption",
        },
      ],
      additionalQuestions: [],
      optionalSelect: [
        {
          id: 134,
          title: 'Length',
          options: ['long', 'short'],
          axiosParamName: 'length',
        },
      ],
    },

    {
      id: 15,
      header: 'Marketing',
      title: 'C* Image to Product Description',
      description: 'Upload any image to create powerful product description in seconds.',
      url: 'visual-prod-description-custom',
      axiosURL: 'generateVisualProductDescriptionC',
      axiosHeader: 'visualProductDescription',
      Img: OneLineIcon,
      formType: 'regular',
      relevanceRequest: false,
      easyRequest: true,
      imgGenerate: false,
      limits: {
        credits: 4,
      },
      requiredQuestions: [
        {
          id: 134,
          title: 'Upload Image? ',
          type: 'upload',
          placeholder: '+ Upload Image',
          axiosParamName: 'url',
        },

        {
          id: 135,
          title: 'Keywords',
          type: 'input',
          placeholder: 'Enter Keywords',
          axiosParamName: 'key_words',
          tooltipAnchor: 'url_mark',
          tooltipText: "Keywords you'd like Klevere to focus on in your product description",
        },
      ],
      additionalQuestions: [],
      optionalSelect: [
        {
          id: 136,
          title: 'Length',
          options: ['long', 'short'],
          axiosParamName: 'length',
        },
      ],
    },

    {
      id: 155,
      header: 'Marketing',
      title: '[GPT-4o] Image to Product Description',
      description: 'Upload any image to create powerful product description in seconds.',
      url: 'visual-prod-description-custom-gpt-4o',
      axiosURL: 'generateVisualProductDescriptionGpt4o',
      axiosHeader: 'visualProductDescription',
      Img: OneLineIcon,
      formType: 'regular',
      relevanceRequest: false,
      easyRequest: true,
      imgGenerate: false,
      limits: {
        credits: 4,
      },
      requiredQuestions: [
        {
          id: 134,
          title: 'Upload Image? ',
          type: 'upload',
          placeholder: '+ Upload Image',
          axiosParamName: 'url',
        },

        {
          id: 135,
          title: 'Keywords',
          type: 'input',
          placeholder: 'Enter Keywords',
          axiosParamName: 'key_words',
          tooltipAnchor: 'url_mark',
          tooltipText: "Keywords you'd like Klevere to focus on in your product description",
        },
      ],
      additionalQuestions: [],
      optionalSelect: [
        {
          id: 136,
          title: 'Length',
          options: ['long', 'short'],
          axiosParamName: 'length',
        },
      ],
    },

    {
      id: 16,
      header: 'Marketing',
      title: 'C* Feedback to Webpage',
      description: 'Get comprehensive feedback on your webpage or landing page.',
      Img: ThreeLinesIcon,
      url: 'webpage-feedback-custom',
      axiosURL: 'generateWebsiteFeedback',
      axiosHeader: 'websiteFeedback',
      relevanceRequest: false,
      easyRequest: true,
      imgGenerate: false,
      limits: {
        words: 200,
        credits: 9,
      },
      requiredQuestions: [
        {
          id: 135,
          title: 'Enter Webpage URL',
          type: 'input',
          placeholder: 'Link..',
          axiosParamName: 'website',
          tooltipAnchor: 'url_mark',
          tooltipText: "The URL of the webpage you're seeking actionable feedback on.",
        },

        {
          id: 136,
          title: 'Area of focus',
          type: 'input',
          placeholder: 'Context...',
          axiosParamName: 'goal',
          tooltipAnchor: 'point_mark',
          tooltipText: 'E.g. full page',
        },
      ],
      additionalQuestions: [],
      optionalSelect: [],
    },

    {
      id: 166,
      header: 'Marketing',
      title: '[GPT-4o] Feedback to Webpage',
      description: 'Get comprehensive feedback on your webpage or landing page.',
      Img: ThreeLinesIcon,
      url: 'webpage-feedback-custom-gpt-4o',
      axiosURL: 'generateWebsiteFeedbackGpt4o',
      axiosHeader: 'websiteFeedback',
      relevanceRequest: false,
      easyRequest: true,
      imgGenerate: false,
      limits: {
        words: 200,
        credits: 9,
      },
      requiredQuestions: [
        {
          id: 135,
          title: 'Enter Webpage URL',
          type: 'input',
          placeholder: 'Link..',
          axiosParamName: 'website',
          tooltipAnchor: 'url_mark',
          tooltipText: "The URL of the webpage you're seeking actionable feedback on.",
        },

        {
          id: 136,
          title: 'Area of focus',
          type: 'input',
          placeholder: 'Context...',
          axiosParamName: 'goal',
          tooltipAnchor: 'point_mark',
          tooltipText: 'E.g. full page',
        },
      ],
      additionalQuestions: [],
      optionalSelect: [],
    },

    {
      id: 17,
      header: 'Marketing',
      title: 'C* Youtube Video to Blog',
      description: 'Generate full length blog articles based on Youtube video URL’s.',
      Img: ThreeLinesIcon,
      url: 'youtube-custom',
      axiosURL: 'generateBlogFromYoutube',
      axiosHeader: 'blogPost',
      relevanceRequest: false,
      easyRequest: false,
      imgGenerate: false,
      limits: {
        words: 250,
        credits: 6,
      },
      requiredQuestions: [
        {
          id: 137,
          title: 'Enter YouTube video URL',
          type: 'input',
          placeholder: 'Link to YouTube..',
          axiosParamName: 'video_url',
        },

        {
          id: 138,
          title: 'Enter Blog name',
          type: 'input',
          placeholder: 'Blog name..',
          axiosParamName: 'blog_name',
        },
      ],
      additionalQuestions: [
        {
          id: 139,
          title: 'Add some SEO keywords',
          type: 'input',
          placeholder: 'SEO keywords..',
          axiosParamName: 'keywords',
        },
      ],
      optionalSelect: [
        {
          id: 140,
          title: 'Tone',
          options: [...toneData],
          axiosParamName: 'tone',
        },
      ],
    },

    {
      id: 177,
      header: 'Marketing',
      title: '[GPT-4o] Youtube Video to Blog',
      description: 'Generate full length blog articles based on Youtube video URL’s.',
      Img: ThreeLinesIcon,
      url: 'youtube-custom-gpt-4o',
      axiosURL: 'generateBlogFromYoutubeGpt4o',
      axiosHeader: 'blogPost',
      relevanceRequest: false,
      easyRequest: false,
      imgGenerate: false,
      limits: {
        words: 250,
        credits: 6,
      },
      requiredQuestions: [
        {
          id: 137,
          title: 'Enter YouTube video URL',
          type: 'input',
          placeholder: 'Link to YouTube..',
          axiosParamName: 'video_url',
        },

        {
          id: 138,
          title: 'Enter Blog name',
          type: 'input',
          placeholder: 'Blog name..',
          axiosParamName: 'blog_name',
        },
      ],
      additionalQuestions: [
        {
          id: 139,
          title: 'Add some SEO keywords',
          type: 'input',
          placeholder: 'SEO keywords..',
          axiosParamName: 'keywords',
        },
      ],
      optionalSelect: [
        {
          id: 140,
          title: 'Tone',
          options: [...toneData],
          axiosParamName: 'tone',
        },
      ],
    },

    {
      id: 18,
      header: 'Marketing',
      title: 'C* Compare Webpages',
      description: 'Analyze and contrast two webpages to gain valuable insights.',
      Img: ThreeLinesIcon,
      url: 'compare-webpages-custom',
      axiosURL: 'generateCompareWebPages',
      axiosHeader: 'comparison',
      easyRequest: true,
      relevanceRequest: false,
      imgGenerate: false,
      limits: {
        words: 200,
        credits: 14,
      },
      requiredQuestions: [
        {
          id: 138,
          title: 'Enter website A',
          type: 'input',
          placeholder: 'Link A..',
          axiosParamName: 'webpage_a',
          tooltipAnchor: 'webpageA_mark',
          tooltipText: 'Webpage A URL; that you wish to analyze and compare with webpage B.',
        },
        {
          id: 139,
          title: 'Enter website B',
          type: 'input',
          placeholder: 'Link B..',
          axiosParamName: 'webpage_b',
          tooltipAnchor: 'webpageA_mark',
          tooltipText: 'Webpage A URL; that you wish to analyze and compare with webpage B.',
        },
        {
          id: 140,
          title: "What's the objective of your comparison?",
          type: 'input',
          placeholder: 'Your goal..',
          axiosParamName: 'goal',
          tooltipAnchor: 'goal_mark',
          tooltipText: "What's the objective of your comparison?",
        },
      ],
      additionalQuestions: [],
      optionalSelect: [],
    },
    {
      id: 188,
      header: 'Marketing',
      title: '[GPT-4] Compare Webpages',
      description: 'Analyze and contrast two webpages to gain valuable insights.',
      Img: ThreeLinesIcon,
      url: 'compare-webpages-custom-gpt-4o',
      axiosURL: 'generateCompareWebPagesGpt4o',
      axiosHeader: 'comparison',
      easyRequest: true,
      relevanceRequest: false,
      imgGenerate: false,
      limits: {
        words: 200,
        credits: 14,
      },
      requiredQuestions: [
        {
          id: 138,
          title: 'Enter website A',
          type: 'input',
          placeholder: 'Link A..',
          axiosParamName: 'webpage_a',
          tooltipAnchor: 'webpageA_mark',
          tooltipText: 'Webpage A URL; that you wish to analyze and compare with webpage B.',
        },
        {
          id: 139,
          title: 'Enter website B',
          type: 'input',
          placeholder: 'Link B..',
          axiosParamName: 'webpage_b',
          tooltipAnchor: 'webpageA_mark',
          tooltipText: 'Webpage A URL; that you wish to analyze and compare with webpage B.',
        },
        {
          id: 140,
          title: "What's the objective of your comparison?",
          type: 'input',
          placeholder: 'Your goal..',
          axiosParamName: 'goal',
          tooltipAnchor: 'goal_mark',
          tooltipText: "What's the objective of your comparison?",
        },
      ],
      additionalQuestions: [],
      optionalSelect: [],
    },
  ],
}

export default marketingTemplatesData
