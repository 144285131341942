import React, {FC} from 'react'
import {ApexOptions} from 'apexcharts'
import ReactApexChart from 'react-apexcharts'
import classes from './GraphicsArea.module.scss'
import {IUsageRateData} from '../../../types/types'

interface IUsageRateBlockProps {
  usageRateData: IUsageRateData
}

const UsageRateBlock: FC<IUsageRateBlockProps> = ({usageRateData}) => {
  const wordsColor = '#282828'
  const imagesColor = '#e4ed96'
  const creditsColor = '#2468ff'

  const options: ApexOptions = {
    chart: {
      type: 'bar',
      height: 200,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '75%',
        borderRadius: 3,
        // endingShape: 'rounded',
      },
    },
    dataLabels: {
      enabled: false,
    },
    grid: {
      show: false, // Set to false to hide horizontal grid lines
    },
    stroke: {
      show: true,
      width: 1,
      colors: ['transparent'],
    },
    xaxis: {
      categories: usageRateData.months.map((month) => {
        return month.charAt(0).toUpperCase() + month.slice(1)
      }),
      axisBorder: {
        show: false,
      },
      labels: {
        style: {
          fontSize: '1rem', // Adjust the font size as needed
        },
      },
      crosshairs: {
        dropShadow: {
          enabled: true,
          blur: 0,
          opacity: 0.6,
        },
      },
    },
    yaxis: {
      reversed:false,
      title: {
        text: '',
      },
      max: 200,
      tickAmount: 20,
      labels: {
        show: false,
        style: {
          fontSize: '0.7rem', // Adjust the font size as needed
        },
      },
    },
    colors: [wordsColor, creditsColor, imagesColor],
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val + '%'
        },
      },
      fixed: {
        enabled: true,
        position: 'topRight',
        offsetX: 0,
        offsetY: 0,
      },
    },
    legend: {
      show: false, // Set to false to hide the legend
    },
  }

  const series = [
    {
      name: 'Words usage',
      data: [...usageRateData.words],
    },
    {
      name: 'Credits usage',
      data: [...usageRateData.credits],
    },
    {
      name: 'Images usage',
      data: [...usageRateData.images],
    },
  ]


  return (
    <div id='chart' className={classes.graphics__usage__chart}>
      <ReactApexChart options={options} series={series} type='bar' height={'100%'} width={'100%'} />
    </div>
  )
}

export default UsageRateBlock
