import {Link} from 'react-router-dom'
import classes from './UniversalNotification.module.scss'
import {FC, useEffect, useState} from 'react'

type UniversalNotificationType = {
  notificatonMessage: string
  upgrade?: boolean
}

const UniversalNotification: FC<UniversalNotificationType> = ({
  notificatonMessage = '',
  upgrade,
}) => {
  const [notification, setNotification] = useState(notificatonMessage)

  const handleClose = () => {
    setNotification('')
  }
  return (
    <>
      {notification.trim() !== '' ? (
        <div className={classes.universal_notification} id='universal-notification'>
          {notificatonMessage}.{' '}
          {!upgrade ? null : (
            <>
              Click{' '}
              <span className={classes.universal_notification__link}>
                <Link to={'/upgrade-plan'}>here</Link>
              </span>{' '}
              to upgrade
            </>
          )}
          
          <span onClick={handleClose} className={classes.universal_notification__close}>
            <i className="bi bi-x-circle-fill" style={{fontSize: 'large'}}></i>
          </span>
        </div>
      ) : null}
    </>
  )
}
export {UniversalNotification}
