import {Link} from 'react-router-dom'
import classes from './LimitNotification.module.scss'
import {FC, useEffect, useState} from 'react'

type LimitNotificationType = {
  errorMessage: string
}

const LimitNotification: FC<LimitNotificationType> = ({errorMessage = ''}) => {
  const [error, setError] = useState(errorMessage)

  const handleClose = () => {
    setError('')
  }
  return (
    <>
      {error.trim() !== '' ? (
        <div className={classes.limit_notification} id='limit-notification'>
          {errorMessage ? errorMessage : 'Your usage have been used up'}. Upgrade{' '}
          <span className={classes.limit_notification__link}>
            <Link to={'/upgrade-plan'}>here</Link>
          </span>{' '}
          to continue usage
          <span onClick={handleClose} className={classes.limit_notification__close}>
            X
          </span>
        </div>
      ) : null}
    </>
  )
}
export {LimitNotification}
