import {useState, type FC, useEffect} from 'react'
import classes from './MembersArea.module.scss'
import DownloadIcon from '../icons/profile/DownloadIcon'
import membersProfileData from '../../data/profile/membersProfileData'
import UniversalHiddenScreen from '../universal-components/hidden-screen/UniversalHiddenScreen'
import InviteColleague from '../homepage/InviteColleague'
import {useAuth} from '../../../app/modules/auth'
import {getUsersLimit} from '../../../utils/getUsersLimit'
import {useGetCompanyMutation} from '../../../services/companyServiceApi'

interface MembersAreaProps {}

const MembersArea: FC<MembersAreaProps> = () => {
  const {currentUser} = useAuth()
  const [usersLimit, setUsersLimit] = useState<number>(10)
  const [company, setCompany] = useState<any>({
    id: 0,
    name: '',
    company_generated_amount: {},
    users: [],
  })

  const [members, setMembers] = useState(
    membersProfileData.map((item) => {
      return {...item, checked: false}
    })
  )

  const [visibleBackground, setVisibleBackground] = useState(false)
  const [getCompany] = useGetCompanyMutation()

  const getCompanyData = async () => {
    try {
      const companyData = await getCompany(currentUser?.company_name).unwrap()
      setCompany(companyData)
    } catch (err) {
      return {err}
    }
  }

  const handleVisibility = () => {
    setVisibleBackground((prev) => !prev)
  }

  const handleCkecked = (e: any) => {
    const id = e.target.name === 'checkAll' ? 'checkAll' : +e.target.name
    const value = e.target.checked

    setMembers((prev) =>
      prev.map((item) => {
        if (id === 'checkAll') {
          return {...item, checked: value}
        }
        return item.id === id ? {...item, checked: value} : item
      })
    )
  }

  const tableRows = members.map((item) => {
    const {id, img, name, role, dateAdded, email, status, checked} = item
    return (
      <tr key={id}>
        <td>
          {/* <input type='checkbox' name={id.toString()} checked={checked} onChange={handleCkecked} /> */}
        </td>
        <td>
          <div className={classes.members__tableImgBlock}>
            <img src={img} alt='member' />
          </div>
          {name}
        </td>
        <td>{role}</td>
        <td>{dateAdded.toLocaleDateString()}</td>
        <td>{email}</td>
        <td>
          <span>{status}</span>
        </td>
      </tr>
    )
  })

  useEffect(() => {
    if (currentUser && currentUser.company_name) {
      setUsersLimit(getUsersLimit(currentUser.subscription.name))
      getCompanyData()
    }
  }, [])

  return (
    <div className={classes.members}>
      <UniversalHiddenScreen
        visible={visibleBackground}
        handleVisibility={handleVisibility}
        contentBlock={<InviteColleague />}
      />
      <div className={classes.members__topBlock}>
        <div className={classes.members__topBlock__headers}>
          <h2 className={classes.members__topBlock__headers__title}>
            Members <sup className='universalComingSoon'>Coming Soon</sup>
          </h2>
          <p>Manage or add new members</p>
        </div>
        <div className={classes.members__topBlock__btnsBlock}>
          <button onClick={() => {}}>
            <DownloadIcon /> Download CSV
          </button>
          <button
            onClick={handleVisibility}
            disabled={usersLimit - company?.users.length <= 0}
            title={
              usersLimit - company?.users.length <= 0
                ? 'Upgrade your plan to invite team members'
                : ''
            }
          >
            Invite member
          </button>
        </div>
      </div>
      <table>
        <thead>
          <tr>
            <th>
              {/* <input
                type='checkbox'
                name='checkAll'
                checked={members.every((item) => item.checked)}
                onChange={handleCkecked}
              /> */}
            </th>
            <th>Member</th>
            <th>Role</th>
            <th>Date added</th>
            <th>Email address</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>{tableRows}</tbody>
      </table>
    </div>
  )
}

export default MembersArea
