import {useFormik} from 'formik'
import * as Yup from 'yup'
import {queryParameters} from '../../../app/modules/auth/core/_requests'
import {Link, useLocation, useNavigate, useParams} from 'react-router-dom'
import {useAuth} from '../../../app/modules/auth/core/Auth'
import {useGoogleLogin} from '@react-oauth/google'
import {useLazyGetUserByTokenQuery, useRegisterMutation} from '../../../services/authServiceApi'
import classes from './AuthStyles.module.scss'
import GoogleIcon from '../icons/inviteform/GoogleIcon'
import registrationFormData from '../../data/auth/registrationFormData'
import {googleAuthentication} from '../../../app/modules/auth/core/_requests'

const inviteCompanyName = queryParameters.get('companyName')

const initialValues = {
  company_name: `${inviteCompanyName ? inviteCompanyName : ''}`,
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  changepassword: '',
  acceptTerms: false,
}

const registrationSchema = Yup.object().shape({
  company_name: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Company name is required'),
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 5 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  firstName: Yup.string()
    .min(2, 'Minimum 2 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('First name is required'),
  lastName: Yup.string()
    .min(2, 'Minimum 2 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Last name is required'),
  password: Yup.string()
    .min(8, 'Minimum 8 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
  changepassword: Yup.string()
    .min(8, 'Minimum 8 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password confirmation is required')
    .oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
  acceptTerms: Yup.boolean().oneOf([true], 'You must accept the terms and conditions'),
  access_token: Yup.string(),
})

export function Registration() {
  const {saveAuth, setCurrentUser} = useAuth()
  const [register, {isLoading}] = useRegisterMutation()
  const [getUserByToken] = useLazyGetUserByTokenQuery()
  const navigate = useNavigate()
  const location = useLocation()

  const queryParams = new URLSearchParams(location.search)
  const code = queryParams.get('code')

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      try {
        await register({
          email: values.email,
          first_name: values.firstName,
          last_name: values.lastName,
          company_name: values.company_name,
          password: values.password,
          code,
        }).unwrap()
        localStorage.setItem('verify_email', 'true')
        navigate('/login', {replace: true})
      } catch (error: any) {
        console.error(error)
        saveAuth(undefined)
        setStatus(
          `${error.data.message ? error.data.message : 'The registration details are incorrect'}`
        )
        setSubmitting(false)
      }
    },
  })

  const googleLogin = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      try {
        await googleAuthentication(codeResponse).then(async (res: any) => {
          if (codeResponse.access_token) {
            saveAuth(res.data)
            const user = await getUserByToken(res.data).unwrap()
            setCurrentUser(user)
          }
        })
      } catch (err) {}
    },
    onError: (error) => console.log('Login from Google Failed', error),
  })

  const inputsBlock = registrationFormData.map((item) => {
    const {id, title, inputType, name, placeholder, autocomlete, required} = item
    return (
      <label
        key={id}
        className={`${classes.auth__form__label} ${
          inputType === 'checkbox' ? classes.auth__form__labelCheckbox : undefined
        }`}
      >
        <h4 className={classes.auth__form__label__title}>
          {title} {inputType === 'checkbox' && <a href='#'>Terms</a>}
        </h4>
        <input
          type={inputType}
          placeholder={placeholder}
          required={required}
          {...formik.getFieldProps(name)}
          autoComplete={autocomlete}
        />
        {formik.touched[name as keyof typeof formik.touched] &&
          formik.errors[name as keyof typeof formik.errors] && (
            <span className={classes.messageError} role='alert'>
              {formik.errors[name as keyof typeof formik.errors]}
            </span>
          )}
        {name === 'company_name' &&
          inviteCompanyName &&
          initialValues.company_name !== formik.values.company_name && (
            <span className={classes.messageSuccess} role='alert'>
              You were invited to {initialValues.company_name}. If you change this field - you will
              use Klevere.ai with another organization
            </span>
          )}
      </label>
    )
  })

  return (
    <div className='appAuth'>
      <div className={classes.auth}>
        <div className={classes.auth__topBlock}>
          <h1 className={classes.auth__topBlock__title}>Sign Up</h1>
          {!code && (
            <>
              <button className={classes.auth__topBlock__googleBtn} onClick={() => googleLogin()}>
                <GoogleIcon className={classes.auth__topBlock__googleBtn__svg} />
                Continue with Google
              </button>
              <div className={classes.auth__topBlock__separator}>
                <span>or</span>
              </div>
            </>
          )}
        </div>
        {formik.status && <span className={classes.messageError}>{formik.status}</span>}

        <form className={classes.auth__form} onSubmit={formik.handleSubmit} noValidate>
          {inputsBlock}

          <button
            type='submit'
            className='universalButton'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            {isLoading ? <>Please wait...</> : <>Create account</>}
          </button>
        </form>
        <div className={classes.auth__footer}>
          <span className={classes.auth__signUp}>
            Already have an account?
            <Link to='/auth'>Login</Link>
          </span>
          <p>
            Email <span className={classes.auth__footer__email}>support@klevere.ai</span> or chat
            with us to report any issues
          </p>
        </div>
      </div>
    </div>
  )
}
