import {FC, useState} from 'react'
import {useAuth} from '../../../app/modules/auth'
import {sendInvitationByEmail, getInvitationLink} from '../../../app/modules/auth/core/_requests'
import classes from './InviteColleague.module.scss'
import GoogleIcon from '../icons/inviteform/GoogleIcon'
import LinkIcon from '../icons/inviteform/LinkIcon'

const InviteColleague: FC = () => {
  const [email, setEmail] = useState('')
  const [emailStatus, setEmailStatus] = useState('')
  const [popStatus, setPopStatus] = useState(false)

  const companyName = useAuth().currentUser?.company_name

  const handleEmail = (e: any) => {
    const value = e.target.value
    setEmail(value)
  }

  const sendEmail = async () => {
    setEmailStatus('')
    await sendInvitationByEmail(email, companyName)
      .then(() => {
        setEmailStatus('Link successfully sent to email')
      })
      .catch(() => {
        setEmailStatus(`This email can't get registration link`)
      })
  }

  const unsecuredCopyToClipboard = (text: string, parent: string) => {
    const textArea = document.createElement('textarea')
    const modal = document.querySelector(parent)
    textArea.textContent = text
    if (modal !== null) modal.appendChild(textArea)
    textArea.select()
    try {
      document.execCommand('copy')
    } catch (err) {
      console.error('Unable to copy to clipboard', err)
    }
    if (modal !== null) modal.removeChild(textArea)
  }

  const getLink = async () => {
    if (!companyName) {
      return
    }
    await getInvitationLink(companyName).then((res: any) => {
      if (window.isSecureContext && navigator.clipboard) {
        navigator.clipboard.writeText(res.data)
        setPopStatus(true)
      } else {
        unsecuredCopyToClipboard(res.data, '#kt_modal_invite_user')
        setPopStatus(true)
      }
    })
    setTimeout(() => {
      setPopStatus(false)
    }, 2500)
  }

  return (
    <div className={classes.invite}>
      <h1 className={classes.invite__title}>Invite Colleague</h1>
      <h3 className={classes.invite__subtitle}>Invite a user to your organization</h3>
      <p>Where do you want to send an invite?</p>
      <label className={classes.invite__label}>
        <h4
          className={`${classes.invite__label__message}
            ${
              emailStatus.includes("can't")
                ? classes.invite__label__messageError
                : classes.invite__label__messageSuccess
            }`}
        >
          {emailStatus}
        </h4>
        <input type='email' placeholder="User's email" value={email} onChange={handleEmail} />
      </label>
      <button className={classes.invite__btn} onClick={sendEmail}>
        <GoogleIcon className={classes.invite__btn__svg} />
        Invite by Email
      </button>
      <div className={classes.invite__separator}>
        <span>or</span>
      </div>
      <button className={classes.invite__btn} onClick={getLink}>
        <LinkIcon className={classes.invite__btn__svg} />
        Invite by Link
      </button>
      <span
        className={`${classes.invite__confirmedCopy} ${
          popStatus ? classes.invite__confirmedCopyVisible : undefined
        }`}
      >
        Copied to clipboard
      </span>
    </div>
  )
}

export default InviteColleague
