import type {FC} from 'react'
import classes from './CombinedWorkflow.module.scss'
import {Link} from 'react-router-dom'
import {CustomTooltip} from '../tooltip/Tooltip'
import {useAuth} from '../../../../app/modules/auth'

interface CombinedWorkflowProps {
  title: string
  buttonColor: string
  itemsData: any
}

const CombinedWorkflow: FC<CombinedWorkflowProps> = ({title, buttonColor, itemsData}) => {
  const {currentUser} = useAuth()
  const templatesBlock = itemsData.templates.map((item: any, index: number) => {
    const {id, title, limits, description, url, Img, tooltipText, tooltipAnchor, notWorking} = item
    if (title !== 'Generate Cold Email WITH ICE BREAKER') {
      return (
        <div key={id} className={classes.combined__row__item}>
          <div className={classes.combined__row__item__topBlock}>
            <h3 className={classes.combined__row__item__topBlock__title}>
              {title}{' '}
              {notWorking ? (
                <span className='universalComingSoon'>Currently unavailable</span>
              ) : null}
              {tooltipAnchor && tooltipText ? (
                <CustomTooltip tooltipText={tooltipText} tooltipAnchor={tooltipAnchor} />
              ) : null}
            </h3>
            <p>{description}</p>
          </div>
          <div className={classes.combined__row__item__bottomBlock}>
            <Link to={notWorking ? '' : url}>
              <button className={`universalButton`} style={{background: buttonColor}}>
                Try now
              </button>
            </Link>
            {/* <div className={classes.combined__row__item__bottomBlock__imgBlock}>
            <Img className={classes.svg} />
          </div> */}
          </div>
        </div>
      )
    } else if (
      currentUser?.email === 'girskiy.andrey@gmail.com' ||
      currentUser?.email === 'yuriiklymenko23@gmail.com' ||
      currentUser?.email === 'danny@klevere.ai'
    ) {
      return (
        <div key={id} className={classes.combined__row__item}>
          <div className={classes.combined__row__item__topBlock}>
            <h3 className={classes.combined__row__item__topBlock__title}>
              {title}{' '}
              {notWorking ? (
                <span className='universalComingSoon'>Currently unavailable</span>
              ) : null}
              {tooltipAnchor && tooltipText ? (
                <CustomTooltip tooltipText={tooltipText} tooltipAnchor={tooltipAnchor} />
              ) : null}
            </h3>
            <p>{description}</p>
          </div>
          <div className={classes.combined__row__item__bottomBlock}>
            <Link to={notWorking ? '' : url}>
              <button className={`universalButton`} style={{background: buttonColor}}>
                Try now
              </button>
            </Link>
            {/* <div className={classes.combined__row__item__bottomBlock__imgBlock}>
              <Img className={classes.svg} />
            </div> */}
          </div>
        </div>
      )
    }
  })

  const workflowsBlock = itemsData.workflows.map((item: any) => {
    const {id, title, description, url, Img, tooltipText, tooltipAnchor} = item
    return (
      <div key={id} className={classes.combined__row__item}>
        <div className={classes.combined__row__item__topBlock}>
          <h3 className={classes.combined__row__item__topBlock__title}>
            {title}
            {tooltipAnchor && tooltipText ? (
              <CustomTooltip tooltipText={tooltipText} tooltipAnchor={tooltipAnchor} />
            ) : null}
          </h3>
          <p>{description}</p>
        </div>
        <div className={classes.combined__row__item__bottomBlock}>
          <Link to={url}>
            <button className={`universalButton`} style={{background: buttonColor}}>
              Try now
            </button>
          </Link>
          {/* <div className={classes.combined__row__item__bottomBlock__imgBlock} title='Difficulty'>
            <Img className={classes.svg} />
          </div> */}
        </div>
      </div>
    )
  })

  return (
    <div className={`universalPage ${classes.combined}`}>
      <div className='universalTopBlock'>
        <h1 className='universalTopTitle'>{title} Workflows</h1>
      </div>
      <div className={classes.combined__row}>
        {templatesBlock} {workflowsBlock}
      </div>
    </div>
  )
}

export default CombinedWorkflow
