import axios from 'axios'
import {IWorkflowFormInputs} from '../../types/types'

interface IUseDalleRequestProps {
  axiosURL: string
  requiredQuestions: [] | any
  additionalQuestions?: [] | any
  optionalSelect?: [] | any
  inputs: IWorkflowFormInputs
  setImages: Function
  myRef?: React.RefObject<HTMLFormElement>
  setIsLoading?: Function
  setMessage?: Function
  handleMessageVisiability?: Function
  setError: Function
}

interface IUseDalleRequestResult {
  handleForm: (e: React.FormEvent | any) => void
  axiosRequest: Function
}

const useDalleRequest = ({
  myRef,
  axiosURL = '',
  requiredQuestions = [],
  additionalQuestions = [],
  optionalSelect = [],
  inputs,
  setImages = () => {},
  setIsLoading = () => {},
  setMessage = () => {},
  handleMessageVisiability = () => {},
  setError = () => {},
}: IUseDalleRequestProps): IUseDalleRequestResult => {
  const API_URL = process.env.REACT_APP_GPT_API_URL
  const axiosParams = {
    [requiredQuestions[0].axiosParamName]: inputs.requiredQuestion1,
    [additionalQuestions[0]?.axiosParamName]: inputs.additionalQuestion1,
    [additionalQuestions[1]?.axiosParamName]: inputs.additionalQuestion2,
    [optionalSelect[0]?.axiosParamName]: inputs.optionalSelect1,
    [optionalSelect[1]?.axiosParamName]: inputs.optionalSelect2,
    [optionalSelect[2]?.axiosParamName]: inputs.optionalSelect3,
    [optionalSelect[3]?.axiosParamName]: inputs.optionalSelect4,
    [optionalSelect[4]?.axiosParamName]: inputs.optionalSelect5,
  }

  const axiosRequest = () => {
    setIsLoading(true)
    const arr: any[] = []

    const req1 = new Promise((resolve, reject) => {
      axios
        .post(`${API_URL}/${axiosURL}`, axiosParams)
        .then((res) => {
          arr.push(res.data)
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })

    const req2 = new Promise((resolve, reject) => {
      axios
        .post(`${API_URL}/${axiosURL}`, axiosParams)
        .then((res) => {
          arr.push(res.data)
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })

    Promise.all([req1, req2])
      .then((res) => {
        setImages(arr.flat()) //remove flat after changes on back
      })
      .catch((err) => {
        setMessage(
          `Oops!.. ${err.response.data.message ? err.response.data.message : 'An error occured'}`
        )
        setError(err.response.data.message || 'An error occured')
        handleMessageVisiability()
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const handleForm = (e: React.FormEvent | any) => {
    e?.type === 'submit' && e.preventDefault()
    const form = myRef?.current
    if (form && form.checkValidity()) {
      axiosRequest()
    }
  }

  return {handleForm, axiosRequest}
}

export default useDalleRequest
