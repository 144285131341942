// apiSlice.js
import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'

const API_URL = process.env.REACT_APP_API_URL

export const userServiceApi = createApi({
  reducerPath: 'user',
  baseQuery: fetchBaseQuery({baseUrl: `${API_URL}/user`}),
  endpoints: (builder) => ({
    getUsers: builder.query({
      queryFn: async (query) => {
        const response = await fetch(`${API_URL}/user_all?${query}`)
        if (!response.ok) {
          throw new Error('Failed to fetch users')
        }
        return response.json()
      },
    }),
    getUserById: builder.query({
      queryFn: async (id) => {
        const response = await fetch(`${API_URL}/${id}`)
        if (!response.ok) {
          throw new Error('Failed to fetch user by id')
        }
        return response.json()
      },
    }),
    createUser: builder.mutation({
      query: (user) => ({
        url: '/create',
        method: 'PUT',
        body: user,
      }),
    }),
    updateUser: builder.mutation({
      query: (user) => ({
        url: `/${user.id}`,
        method: 'PATCH',
        body: user,
      }),
    }),
    deleteUser: builder.mutation({
      query: (userId) => ({
        url: `/${userId}`,
        method: 'DELETE',
      }),
    }),
  }),
})

export const {
  useGetUsersQuery,
  useGetUserByIdQuery,
  useCreateUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
} = userServiceApi
