import {gptServiceApi} from './../services/gptServiceApi'
import {configureStore} from '@reduxjs/toolkit'
import {setupListeners} from '@reduxjs/toolkit/query'
import {companyServiceApi} from '../services/companyServiceApi'
import {authServiceApi} from '../services/authServiceApi'
import {mailServiceApi} from '../services/mailServiceApi'
import {userServiceApi} from '../services/userServiceApi'
import {TypedUseSelectorHook, useDispatch, useSelector} from 'react-redux'
export const store = configureStore({
  reducer: {
    [authServiceApi.reducerPath]: authServiceApi.reducer,
    [companyServiceApi.reducerPath]: companyServiceApi.reducer,
    [mailServiceApi.reducerPath]: mailServiceApi.reducer,
    [userServiceApi.reducerPath]: userServiceApi.reducer,
    [gptServiceApi.reducerPath]: gptServiceApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      authServiceApi.middleware,
      companyServiceApi.middleware,
      mailServiceApi.middleware,
      userServiceApi.middleware,
      gptServiceApi.middleware,
    ]),
})
setupListeners(store.dispatch)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
