import axios from 'axios'
import {IWorkflowFormInputs} from '../../types/types'
import { Dispatch, SetStateAction } from 'react'

interface IUseChatGPTRequestProps {
  axiosURL: string
  requiredQuestions: [] | any
  additionalQuestions: [] | any
  optionalSelect: [] | any
  inputs: IWorkflowFormInputs
  axiosHeader: string
  setCurrentAnswer: Function
  myRef: React.RefObject<HTMLFormElement>
  setIsLoading?: Function
  setError: Function
  activeIndex?: number | null
  setActiveIndex?: Dispatch<SetStateAction<number | null>> 
}

interface IUseChatGPTRequestResult {
  handleForm: (e: React.FormEvent | any) => void
}

const useChatGPTRequest = ({
  myRef,
  axiosURL,
  axiosHeader,
  requiredQuestions,
  additionalQuestions,
  optionalSelect,
  inputs,
  setCurrentAnswer,
  setIsLoading = () => {},
  setError,
  activeIndex,
  setActiveIndex
}: IUseChatGPTRequestProps): IUseChatGPTRequestResult => {
  const API_URL = process.env.REACT_APP_GPT_API_URL

  const handleForm = (e: React.FormEvent | any) => {
    e?.type === 'submit' && e.preventDefault()
    const form = myRef.current
    if (form && form.checkValidity()) {
      setIsLoading(true)
      axios
        .post(`${API_URL}/${axiosURL}`, {
          [requiredQuestions[0].axiosParamName]: inputs.requiredQuestion1,
          [requiredQuestions[1]?.axiosParamName]: inputs.requiredQuestion2,
          [requiredQuestions[2]?.axiosParamName]: inputs.requiredQuestion3,
          [requiredQuestions[3]?.axiosParamName]: inputs.requiredQuestion4,
          [requiredQuestions[4]?.axiosParamName]: inputs.requiredQuestion5,
          [requiredQuestions[5]?.axiosParamName]: inputs.requiredQuestion6,

          [additionalQuestions[0]?.axiosParamName]: inputs.additionalQuestion1,
          [additionalQuestions[1]?.axiosParamName]: inputs.additionalQuestion2,
          [additionalQuestions[2]?.axiosParamName]: inputs.additionalQuestion3,

          [optionalSelect[0]?.axiosParamName]: inputs.optionalSelect1,
          [optionalSelect[1]?.axiosParamName]: inputs.optionalSelect2,

          socialMedia: inputs.media,
          amountValue: inputs.variations,
          outputLanguage: inputs.language,
          // linkedin_url: inputs.linkedin_url,
        })
        .then((res) => {
          setCurrentAnswer(res.data[axiosHeader].message)

        }).then(()=>{
          if( setActiveIndex && (activeIndex || activeIndex === 0)){
            setActiveIndex(activeIndex+1)
          }
        })
        .catch((err) => {
          setCurrentAnswer(
            `Oops!.. ${err.response.data.message ? err.response.data.message : 'An error occured'}`
          )
          setError(err.response.data.message || 'An error occured')
          console.log(err)
        })
        .finally(() => {
          setIsLoading(false)
        })

      setCurrentAnswer("Klevere's AI is working on your output...")
    }
  }

  return {handleForm}
}

export default useChatGPTRequest
