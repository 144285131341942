import type {FC} from 'react'
import financeTemplatesData from '../../../data/finance/financeTemplatesData'
import {useParams} from 'react-router-dom'
import CombinedGeneration from '../../universal-components/combined-generation/CombinedGeneration'

interface FinanceTemplatePageProps {}

const FinanceTemplatePage: FC<FinanceTemplatePageProps> = () => {
  const {templateUrl} = useParams()
  const template: any = financeTemplatesData.templates.find((item: any) =>
    item.url.includes(templateUrl)
  )

  return <CombinedGeneration templateItem={template} />
}

export default FinanceTemplatePage
