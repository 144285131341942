import {useEffect, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {
  queryParameters,
  requestPassword,
  resetPassword,
} from '../../../app/modules/auth/core/_requests'
import classes from './AuthStyles.module.scss'
import {Link, useNavigate} from 'react-router-dom'

const initialValues = {
  password: '',
  changepassword: '',
}

const NewPasswordSchmea = Yup.object().shape({
  password: Yup.string()
    .min(8, 'Minimum 8 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
  changepassword: Yup.string()
    .min(8, 'Minimum 8 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Confirm Password is required')
    .oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
})

export function NewPassword() {
  const navigate = useNavigate()

  const token = queryParameters.get('token')

  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const [hasSucceeded, setHasSucceeded] = useState<boolean | undefined>(undefined)

  useEffect(() => {
    if (token === null || token === '' || token === undefined) {
      navigate('/auth', {replace: true})
    }
  }, [])
  const formik = useFormik({
    initialValues,
    validationSchema: NewPasswordSchmea,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      if (token !== null) {
        setLoading(true)
        setHasErrors(undefined)
        resetPassword(token, values.password)
          .then(({data: {result}}) => {
            setHasErrors(false)
            setLoading(false)
            setHasSucceeded(true)
          })
          .catch((err) => {
            setHasErrors(true)
            setLoading(false)
            setStatus('Something went wrong, try again')
          })
          .finally(() => {
            setLoading(false)
            setSubmitting(false)
          })
      }
    },
  })

  return (
    <div className='appAuth'>
      <div className={classes.auth}>
        <div className={classes.auth__topBlock}>
          <h1 className={classes.auth__topBlock__title}>Forgot password?</h1>
          <span className={classes.auth__topBlock__subTitle}>Enter your new password.</span>
        </div>
        {hasSucceeded ? (
          <span className={classes.messageSuccess}>
            Your password reset is successful. Please log in with your new details
          </span>
        ) : null}
        {hasErrors ? (
          <span className={classes.messageError}>
            Sorry, looks like there are some errors detected, please try again.
          </span>
        ) : null}

        <form className={classes.auth__form} onSubmit={formik.handleSubmit} noValidate>
          <label className={classes.auth__form__label}>
            <h4 className={classes.auth__form__label__title}>New password</h4>
            <input
              type='password'
              placeholder='Password'
              required={true}
              {...formik.getFieldProps('password')}
              autoComplete='off'
            />
            {formik.touched.password && formik.errors.password && (
              <span className={classes.messageError} role='alert'>
                {formik.errors.password}
              </span>
            )}
          </label>
          <label className={classes.auth__form__label}>
            <h4 className={classes.auth__form__label__title}>Confirm password</h4>
            <input
              type='password'
              placeholder='Confirm password'
              required={true}
              {...formik.getFieldProps('changepassword')}
              autoComplete='off'
            />
            {formik.touched.changepassword && formik.errors.changepassword && (
              <span className={classes.messageError} role='alert'>
                {formik.errors.changepassword}
              </span>
            )}
          </label>
          <div className={classes.auth__form__linkBlock}>
            <Link to='/auth'>Back to Login</Link>
          </div>
          <button type='submit' className='universalButton' disabled={loading || !formik.isValid}>
            {loading ? <>Please wait...</> : <>Continue</>}
          </button>
        </form>
      </div>
    </div>
  )
}
