import {FC} from 'react'

type Props = {className?: string; width?: string; height?: string; color?: string}

const DownloadIcon: FC<Props> = ({className, width = '20', height = '20', color = '#282828'}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 20 20'
      fill='none'
      className={className}
    >
      <path
        d='M12.7169 18.5422H7.28356C3.19189 18.5422 1.44189 16.7922 1.44189 12.7006V12.5922C1.44189 8.89222 2.90023 7.10888 6.16689 6.80055C6.50023 6.77555 6.81689 7.02555 6.85023 7.36722C6.88356 7.70888 6.63356 8.01722 6.28356 8.05055C3.66689 8.29222 2.69189 9.52555 2.69189 12.6006V12.7089C2.69189 16.1006 3.89189 17.3006 7.28356 17.3006H12.7169C16.1086 17.3006 17.3086 16.1006 17.3086 12.7089V12.6006C17.3086 9.50888 16.3169 8.27555 13.6502 8.05055C13.3086 8.01722 13.0502 7.71722 13.0836 7.37555C13.1169 7.03388 13.4086 6.77555 13.7586 6.80888C17.0752 7.09222 18.5586 8.88388 18.5586 12.6089V12.7172C18.5586 16.7922 16.8086 18.5422 12.7169 18.5422Z'
        fill={color}
      />
      <path
        d='M10 13.0243C9.65833 13.0243 9.375 12.741 9.375 12.3993V1.66602C9.375 1.32435 9.65833 1.04102 10 1.04102C10.3417 1.04102 10.625 1.32435 10.625 1.66602V12.3993C10.625 12.7493 10.3417 13.0243 10 13.0243Z'
        fill={color}
      />
      <path
        d='M10 13.9576C9.8417 13.9576 9.68337 13.8992 9.55837 13.7742L6.7667 10.9826C6.52503 10.7409 6.52503 10.3409 6.7667 10.0992C7.00837 9.85755 7.40837 9.85755 7.65003 10.0992L10 12.4492L12.35 10.0992C12.5917 9.85755 12.9917 9.85755 13.2334 10.0992C13.475 10.3409 13.475 10.7409 13.2334 10.9826L10.4417 13.7742C10.3167 13.8992 10.1584 13.9576 10 13.9576Z'
        fill={color}
      />
    </svg>
  )
}

export default DownloadIcon
