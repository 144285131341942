import type {FC} from 'react'
import classes from './RecentActivity.module.scss'
import EditIcon from '../icons/homepage/EditIcon'
import DelIcon from '../icons/homepage/DelIcon'

interface RecentActivityAreaProps {}

const RecentActivityArea: FC<RecentActivityAreaProps> = () => {
  const rowsCount = [1, 2, 3, 4]

  const activity = [
    {name: 'Personalize LinkedIn Request', type: 'Sales Workflow', lastOpened: 'Today'},
    {name: 'Youtube video to blog', type: 'Marketing Workflow', lastOpened: 'Today'},
    {name: "Screen CV's using AI", type: 'Human Resource Workflow', lastOpened: 'Today'},
    {name: 'Blog article', type: 'Marketing Template', lastOpened: 'Yesterday'},
  ]

  const tableRows = rowsCount.map((item, index) => {
    return (
      <tr key={item}>
        <td>{activity[index].name}</td>
        <td>{activity[index].type}</td>
        <td>{activity[index].lastOpened}</td>
        <td>
          <EditIcon className={classes.svg} />
        </td>
        <td>
          <DelIcon className={classes.svg} />
        </td>
      </tr>
    )
  })

  return (
    <div className={classes.recent}>
      <h3 className={classes.recent__title}>
        Recent Activity <sup className='universalComingSoon'>Coming Soon</sup>
      </h3>

      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Type</th>
            <th>Last Opened</th>
            <th colSpan={2}>Actions</th>
          </tr>
        </thead>
        <tbody>{tableRows}</tbody>
      </table>
    </div>
  )
}

export default RecentActivityArea
