import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'
const API_URL = process.env.REACT_APP_API_URL

export const mailServiceApi = createApi({
  reducerPath: 'mailServiceApi',
  baseQuery: fetchBaseQuery({baseUrl: `${API_URL}/mail`}), // Change the baseUrl to your API endpoint
  endpoints: (builder) => ({
    sendInvitationByEmail: builder.mutation({
      query: ({email, company_name}) => ({
        url: '/send-invitation-email',
        method: 'POST',
        body: {email, company_name},
      }),
    }),
    getInvitationLink: builder.mutation({
      query: (company_name) => ({
        url: '/request_link',
        method: 'POST',
        body: {company_name},
      }),
    }),
  }),
})

export const {useSendInvitationByEmailMutation, useGetInvitationLinkMutation} = mailServiceApi
