import {useState, type FC} from 'react'
import classes from './ImagesPage.module.scss'
import ImagesForm from './ImagesForm'
import ImagesArea from './ImagesArea'
import {LimitNotification} from '../universal-components/limit-notification/LimitNotification'

interface ImagesPageProps {}

const ImagesPage: FC<ImagesPageProps> = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [triggerRegenerate, setTriggerRegenerate] = useState<number | string>('')
  const [message, setMessage] = useState<string>('')
  const [isVisibleMessage, setIsVisibleMessage] = useState(false)
  const [images, setImages] = useState<string[]>([
    '/new-design/image/1.jpg',
    '/new-design/image/2.jpg',
  ])
  const [error, setError] = useState('')

  const handleMessageVisiability = () => {
    setIsVisibleMessage(true)

    setTimeout(() => {
      setIsVisibleMessage(false)
    }, 2000)
  }

  return (
    <div className={`universalPage ${classes.images}`}>
      {error.trim() !== '' ? <LimitNotification errorMessage={error} /> : null}
      <div className={classes.images__leftBlock}>
        <div className={`universalTopBlock ${classes.images__leftBlock__universalTopBlock}`}>
          <h1 className='universalTopTitle'>Image Generation</h1>
          <div className='universalBreadcrumbs'>
            <span>Home</span>
            <span>/</span>
            <span className='universalBreadcrumbs__lastChild'>Image Generation</span>
          </div>
        </div>
        <ImagesForm
          setImages={setImages}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          triggerRegenerate={triggerRegenerate}
          setMessage={setMessage}
          handleMessageVisiability={handleMessageVisiability}
          setError={setError}
        />
      </div>
      <ImagesArea
        images={images}
        setTriggerRegenerate={setTriggerRegenerate}
        isLoading={isLoading}
        message={message}
        setMessage={setMessage}
        isVisibleMessage={isVisibleMessage}
        handleMessageVisiability={handleMessageVisiability}
      />
    </div>
  )
}

export default ImagesPage
