import {useState, useEffect, type FC} from 'react'
import classes from './GraphicsArea.module.scss'
import UsageRateBlock from './UsageRateBlock'
import WordsUsedBlock from './WordsUsedBlock'
import {getTodayDate} from '../../../utils/getTodayDate'
import {getMonthName} from '../../../utils/getMonthName'
import {getWordsLimitByPlanName} from '../../../utils/getWordsLimitByPlanName'
import {getImagesLimitByPlanName} from '../../../utils/getImagesLimitByPlanName'
import {getCreditsLimitByPlanName} from '../../../utils/getCreditsLimitByPlanName'
import UniversalHiddenScreen from '../universal-components/hidden-screen/UniversalHiddenScreen'
import InviteColleague from './InviteColleague'
import {getUsersLimit} from '../../../utils/getUsersLimit'
import {IUsageRateData} from '../../../types/types'

interface GraphicsAreaProps {
  currentUser: any
  company: any
}

const GraphicsArea: FC<GraphicsAreaProps> = ({
  currentUser,
  company = {id: 0, name: '', company_generated_amount: {}, users: []},
}) => {
  const {month, year} = getTodayDate()
  const wordsLimit = getWordsLimitByPlanName(currentUser?.subscription.name)
  const imagesLimit = getImagesLimitByPlanName(currentUser?.subscription.name)
  const creditsLimit = getCreditsLimitByPlanName(currentUser?.subscription.name)
  const [visibleBackground, setVisibleBackground] = useState(false)
  const [usersLimit, setUsersLimit] = useState<number>(10)
  const [usageRateData, setUsageRateData] = useState<IUsageRateData>({
    words: [0.5, 0.5, 0.5, 0.5, 0.5, 0.5],
    credits: [0.5, 0.5, 0.5, 0.5, 0.5, 0.5],
    images: [0.5, 0.5, 0.5, 0.5, 0.5, 0.5],
    months: ['jan', 'feb', 'mar', 'apr', 'may', 'jun'],
  })
  const wordsUsagePercentage = Math.round(
    (company?.company_generated_amount?.[year]?.[getMonthName(month)].words / wordsLimit) * 100 ||
      0.01
  )

  const getUsageRateData = (companyData: any) => {
    let words: number[] = []
    let credits: number[] = []
    let images: number[] = []
    let months: string[] = []

    const updateArrays = (obj: any, monthIndex: number) => {
      const monthName = getMonthName(monthIndex)
      words.unshift(
        Math.round(((obj?.[monthName].words ? obj[monthName].words : 0) / wordsLimit) * 100)
      )
      credits.unshift(
        Math.round(((obj?.[monthName].credits ? obj[monthName].credits : 0) / creditsLimit) * 100)
      )
      images.unshift(
        Math.round(((obj?.[monthName].images ? obj[monthName].images : 0) / imagesLimit) * 100)
      )
      months.unshift(monthName.slice(0, 3))
    }

    for (let i = month; i >= month - 5 && i >= 0; i--) {
      let obj = companyData?.company_generated_amount[year]
      updateArrays(obj, i)
    }
    if (words.length < 6) {
      let obj = companyData?.company_generated_amount[year - 1]
      for (let i = 11; words.length < 6; i--) {
        updateArrays(obj, i)
      }
    }
    setUsageRateData({words: words, credits: credits, images: images, months: months})
  }

  // const getCompanyData = async () => {
  //   try {
  //     const companyData = await getCompany(currentUser?.company_name).unwrap()
  //     // setCompany(companyData)
  //   } catch (err) {
  //     return {err}
  //   }
  // }

  const handleVisibility = () => {
    setVisibleBackground((prev) => !prev)
  }

  const mockedLayer = usageRateData.months.map((item, index) => {
    const emptyMonth =
      !usageRateData.words[index] && !usageRateData.credits[index] && !usageRateData.images[index]
    return (
      <div key={index} className={classes.graphics__usage__mocked__month}>
        <div
          className={classes.graphics__usage__mocked__month__item}
          style={{height: emptyMonth ? Math.round(Math.random() * 100) + '%' : 0}}
          title='No words data for this period'
        ></div>
        <div
          className={classes.graphics__usage__mocked__month__item}
          style={{height: emptyMonth ? Math.round(Math.random() * 100) + '%' : 0}}
          title='No credits data for this period'
        ></div>
        <div
          className={classes.graphics__usage__mocked__month__item}
          style={{height: emptyMonth ? Math.round(Math.random() * 100) + '%' : 0}}
          title='No images data for this period'
        ></div>
      </div>
    )
  })

  useEffect(() => {
    if (currentUser && currentUser.company_name) {
      setUsersLimit(getUsersLimit(currentUser.subscription.name))
    }
  }, [])
  useEffect(() => {
    getUsageRateData(company)
  }, [company])

  return (
    <div className={classes.graphics}>
      <UniversalHiddenScreen
        visible={visibleBackground}
        handleVisibility={handleVisibility}
        contentBlock={<InviteColleague />}
      />
      <div className={classes.graphics__progress}>
        <h4 className={classes.graphics__progress__title}>
          You have {usersLimit - company?.users.length} spots left in team.
        </h4>
        <div className={classes.graphics__progress__bar}>
          <div
            className={classes.graphics__progress__bar__line}
            style={{width: `${(company?.users.length / usersLimit) * 100}%`}}
          ></div>
        </div>

        <button
          className={classes.graphics__progress__btn}
          onClick={handleVisibility}
          disabled={usersLimit - company?.users.length <= 0}
          title={
            usersLimit - company?.users.length <= 0
              ? 'Upgrade your plan to invite team members'
              : ''
          }
        >
          Invite Team Members
        </button>
      </div>
      <div className={classes.graphics__usage}>
        <h3 className={classes.graphics__usage__title}>Usage Rate</h3>
        <div className={classes.graphics__usage__mocked}>{mockedLayer}</div>
        <UsageRateBlock usageRateData={usageRateData} />
      </div>

      <div className={classes.graphics__wordsUsage}>
        <h3 className={classes.graphics__wordsUsage__title}>Words Used</h3>
        <span className={classes.graphics__wordsUsage__prop}>{wordsUsagePercentage}%</span>
        <WordsUsedBlock currentUsage={wordsUsagePercentage} />
      </div>
    </div>
  )
}

export default GraphicsArea
