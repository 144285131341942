import OneLineIcon from '../../components/icons/workflow/OneLineIcon'
import ThreeLinesIcon from '../../components/icons/workflow/ThreeLinesIcon'
import toneData from '../universal-data/toneData'

const financeTemplatesData = {
  workflows: [ 
    {
      id: 1,
      header: 'Sales',
      title: 'Personalize outreach using LinkedIn',
      description: 'Convert more leads by personalizing outreach.',
      Img: ThreeLinesIcon,
      url: 'linkedin-outreach',
      axiosURL: '46f74fc2-bb8f-4596-afbd-96495109514b',
      axiosHeader: '',
      relevanceRequest: true,
      easyRequest: true,
      formType: 'regular',
      requiredQuestions: [
        {
          id: 11,
          title: 'LinkedIn profile URL',
          type: 'input',
          placeholder: 'Link to profile..',
          axiosParamName: 'linkedin_url',
        },

        {
          id: 12,
          title: 'First Name',
          type: 'input',
          placeholder: 'Enter First Name..',
          axiosParamName: 'first_name',
        },
      ],
      additionalQuestions: [],
    },
  ],
  templates: [
    {
      id: 1,
      header: 'Sales',
      title: 'Subject Lines',
      description: 'Improve your email open rates with captivating subject lines.',
      url: 'subject-lines',
      axiosURL: 'generateSubjectLines',
      axiosHeader: 'subjectLines',
      Img: OneLineIcon,
      relevanceRequest: false,
      easyRequest: true,
      formType: 'regular',
      requiredQuestions: [
        {
          id: 11,
          title: 'What does your company do?',
          type: 'input',
          placeholder: 'Enter...',
          axiosParamName: 'product',
        },

        {
          id: 12,
          title: 'What are your unique selling points?',
          type: 'input',
          axiosParamName: 'points',
        },
      ],
      additionalQuestions: [
        {
          id: 13,
          title: 'Who is your target audience?',
          type: 'input',
          placeholder: 'Enter...',
          axiosParamName: 'audience',
        },
      ],
      optionalSelect: [
        {
          id: 14,
          title: 'Tone',
          options: [...toneData],
          axiosParamName: 'tone',
        },
      ],
    },
  ],
}

export default financeTemplatesData
