import {useState, type FC} from 'react'
import classes from './ModalPage.module.scss'
import navData from '../../data/navs/navData'
import CheckmarkIcon from '../icons/modalform/CheckmarkIcon'
import SelectArrowIcon from '../icons/modalform/SelectArrowIcon'

interface ModalPageProps {}

interface IFormInputs {
  workspace: string | undefined
  companySize: string
  infoSource: string
}

const ModalPage: FC<ModalPageProps> = () => {
  const navGroup = navData.find((item) => item.groupName === 'templates')
  const workspaces = navGroup?.navs
  const companySize = ['1-100', '100-500', '500-5000', '>5000']
  const infoSource = ['Social media', 'Commercial', 'Friends', 'Other']
  const checkedRadio = '#2468FF'
  const standartRadio = '#616161'

  const [inputs, setInputs] = useState<IFormInputs>({
    workspace: workspaces?.[0].url,
    companySize: companySize[0],
    infoSource: infoSource[0],
  })

  const handleInputs = (e: any) => {
    const value = e.target.value
    const name = e.target.name

    setInputs((prev) => ({...prev, [name]: value}))
  }

  const handleForm = (e: any) => {
    e.preventDefault()
    console.log(inputs)
  }

  const radioInputsBlock = workspaces?.map((item, index) => {
    const {id, name, Img, url} = item
    return (
      <label
        key={id}
        className={`${classes.modal__form__group__workspaceBlock__label} ${
          inputs.workspace === url
            ? classes.modal__form__group__workspaceBlock__labelActive
            : undefined
        }`}
      >
        <input
          key={id}
          type='radio'
          id={name}
          name='workspace'
          value={url}
          checked={inputs.workspace === url}
          onChange={handleInputs}
        />
        <Img color={inputs.workspace === url ? checkedRadio : standartRadio} /> {name}
        <span className={classes.modal__form__group__workspaceBlock__label__checkmark}>
          {inputs.workspace === url && <CheckmarkIcon />}
        </span>
      </label>
    )
  })

  const companySizeBlock = companySize.map((item, index) => {
    return (
      <option key={index} value={item}>
        {item}
      </option>
    )
  })

  const infoSourceBlock = infoSource.map((item, index) => {
    return (
      <option key={index} value={item}>
        {item}
      </option>
    )
  })

  return (
    <div className={classes.modal}>
      <form className={classes.modal__form} onSubmit={handleForm}>
        <h3 className={classes.modal__form__title}>Welcome to Klevere</h3>
        <div className={classes.modal__form__group}>
          <h4 className={classes.modal__form__group__title}>
            Please choose your preferred workspace
          </h4>
          <div className={classes.modal__form__group__workspaceBlock}>{radioInputsBlock}</div>
        </div>
        <div className={classes.modal__form__group}>
          <h4 className={classes.modal__form__group__title}>What is your company size?</h4>
          <select
            className={classes.modal__form__group__select}
            name='companySize'
            value={inputs.companySize}
            onChange={handleInputs}
          >
            {companySizeBlock}
          </select>
          <span className={classes.modal__form__group__selectArrow}>
            <SelectArrowIcon />
          </span>
        </div>
        <div className={classes.modal__form__group}>
          <h4 className={classes.modal__form__group__title}>How did you hear about us?</h4>
          <select
            className={classes.modal__form__group__select}
            name='infoSource'
            value={inputs.infoSource}
            onChange={handleInputs}
          >
            {infoSourceBlock}
          </select>
          <span className={classes.modal__form__group__selectArrow}>
            <SelectArrowIcon />
          </span>
        </div>
        <span className={classes.modal__form__btnBlock}>
          <button className='universalButton'>Lets Go</button>
        </span>
      </form>
    </div>
  )
}

export default ModalPage
