import {useState, type FC, useEffect} from 'react'
import classes from './ImagesContainer.module.scss'
import LeftArrowIcon from '../../icons/images/LeftArrowIcon'
import ImageGenerated from '../../universal-components/image-generation/ImageGenerated'
import CopyIcon from '../../icons/images/CopyIcon'
import DownloadIcon from '../../icons/images/DownloadIcon'

interface ImagesContainerProps {
  images: string[]
  isLoading: boolean
  setMessage?: Function
  handleMessageVisiability?: Function
}

const ImagesContainer: FC<ImagesContainerProps> = ({
  images,
  isLoading = false,
  setMessage = () => {},
  handleMessageVisiability = () => {},
}) => {
  const [isVisibleHiddenScreen, setIsVisibleHiddenScreen] = useState<boolean>(false)
  const [currentImage, setCurrentImage] = useState<string>('/new-design/image/1.jpg')

  const handleHiddenScreen = (isVisible = true) => {
    setIsVisibleHiddenScreen(isVisible)
  }

  const handleCurrentImage = (img: string = '') => {
    setCurrentImage(img)
    handleHiddenScreen()
  }

  const copyImgToClipboard = async (img: string) => {
    try {
      /*BASE64 VARIANT */
      // const item= new ClipboardItem({'image/png': img})
      // await navigator.clipboard.write([item])
      /*END OF BASE64 VARIANT*/

      /* URL VARIANT*/
      const response = await fetch(img, {
        headers: {mode: 'no-cors', 'Access-Control-Allow-Origin': '*'},
      })
      const blob = new Blob([await response.blob()], {type: 'image/png'})
      const item = new ClipboardItem({[blob.type]: blob})
      await navigator.clipboard.write([item])
      /* END OF URL VARIANT*/

      setMessage('Copied to clipboard!')
    } catch (err) {
      setMessage('Error! Copying declined!')
    } finally {
      handleMessageVisiability()
    }
  }

  const downloadImg = async (img: string) => {
    try {
      /*BASE64 VARIANT */
      // const link = document.createElement('a');
      // link.href = `data:image/png;base64,${img}`;
      // link.download = 'downloaded-image.jpg';
      /*END OF BASE64 VARIANT*/

      /* URL VARIANT*/
      const response = await fetch(img, {
        headers: {mode: 'no-cors', 'Access-Control-Allow-Origin': '*'},
      })
      const blob = await response.blob()
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = 'image.jpg' // You can customize the filename here
      /* END OF URL VARIANT*/

      link.style.display = 'none'
      document.body.appendChild(link)
      link.click()

      document.body.removeChild(link)
      URL.revokeObjectURL(link.href)
    } catch (error) {
      console.error('Error downloading image:', error)
    }
  }

  const imagesBlock = images.map((item, index) => (
    <ImageGenerated
      key={index}
      image={item}
      isLoadingImg={isLoading}
      onClick={() => handleCurrentImage(item)}
    />
  ))

  useEffect(() => {
    isLoading && handleHiddenScreen(false)
  }, [isLoading])

  return (
    <div className={classes.container}>
      <div
        className={classes.container__hiddenScreen}
        style={{display: isVisibleHiddenScreen ? 'flex' : 'none'}}
      >
        <div className={classes.container__hiddenScreen__btns}>
          <button
            onClick={() => handleHiddenScreen(false)}
            title='Back to images'
            disabled={isLoading}
          >
            <LeftArrowIcon className={classes.svg} />
          </button>
          <span className={classes.container__hiddenScreen__btns__group}>
            {/* <button
              onClick={() => copyImgToClipboard(currentImage)}
              title='Copy to clipboard'
              disabled={isLoading}
            >
              <CopyIcon className={classes.svg} />
            </button>
            <button
              onClick={() => {
                downloadImg(currentImage)
              }}
              title='Download image'
              disabled={isLoading}
            >
              <DownloadIcon className={classes.svg} />
            </button> */}
          </span>
        </div>
        <img src={currentImage} alt='dalle' />
      </div>
      <div
        className={classes.container__images}
        style={{display: isVisibleHiddenScreen ? 'none' : 'grid'}}
      >
        {imagesBlock}
      </div>
    </div>
  )
}

export default ImagesContainer
