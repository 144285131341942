import {useState, type FC, useEffect} from 'react'
import classes from './CurrentPlanBlock.module.scss'
import {Link} from 'react-router-dom'
import {useAuth} from '../../../app/modules/auth'
import {getTodayDate} from '../../../utils/getTodayDate'
import {getMonthName} from '../../../utils/getMonthName'
import {getWordsLimitByPlanName} from '../../../utils/getWordsLimitByPlanName'
import {getCreditsLimitByPlanName} from '../../../utils/getCreditsLimitByPlanName'
import {useGetCompanyMutation} from '../../../services/companyServiceApi'
import {SubscriptionPlansEnum} from '../../../types/types'
import {getImagesLimitByPlanName} from '../../../utils/getImagesLimitByPlanName'
import axios, {AxiosResponse} from 'axios'
import { toast } from 'react-toastify'
interface CurrentPlanBlockProps {}

const CurrentPlanBlock: FC<CurrentPlanBlockProps> = () => {
  const {currentUser} = useAuth()
  const {month, year} = getTodayDate()
  const [getCompany, {data: companyData, isLoading}] = useGetCompanyMutation()

  const [disableCancelBtn,setDisableCancelBtn] = useState(false);

  // const monthUsage = currentUser?.user_generated_amount[year][getMonthName(month).toLowerCase()]
  const wordsLimit = getWordsLimitByPlanName(currentUser?.subscription.name)
  const creditsLimit = getCreditsLimitByPlanName(currentUser?.subscription.name)
  const imagesLimit = getImagesLimitByPlanName(currentUser?.subscription.name)

  

  const [plan, setPlan] = useState({
    name: currentUser?.subscription.name || 'Plan Name',
    validStatus: currentUser?.subscription.status || 'undefined',
    rows: [
      {
        title: 'words',
        totalCount: wordsLimit,
        currentCount: 0,
        disabled: false,
      },
      {
        title: 'images',
        totalCount: imagesLimit,
        currentCount: 0,
        disabled: false,
      },
      {
        title: 'credits',
        totalCount: creditsLimit,
        currentCount: 0,
        disabled: false,
      },
    ],
  })

  const getCompanyUsage = async () => {
    try {
      if (!isLoading && !companyData) {
        const company = await getCompany(currentUser?.company_name).unwrap()
        return {
          usage: company.company_generated_amount[year][getMonthName(month).toLowerCase()],
          credits: company.total_usage.credits,
        }
      }
    } catch (err) {
      return {err}
    }
  }

  const handleCancelSubscription = () => {
    setDisableCancelBtn(true)
    const API_URL = process.env.REACT_APP_API_URL
     axios.post(`${API_URL}/payment/cancel-subscription`, {
      'userId' : currentUser?.id
     }).then((res)=>{
      console.log(res);
      if(res.data.success){
        toast.success(res.data.message)
      }else{
        toast.warning(res.data.message);
      }
      setDisableCancelBtn(false)
     }).catch((e)=>{
        setDisableCancelBtn(false)
        toast.error('Failed to cancel subscription');
     })
  }

  useEffect(() => {
    getCompanyUsage().then(async (res: any) => {
      if (res && res.usage && currentUser && currentUser.subscription) {
        setPlan({
          name: currentUser?.subscription.name || 'Plan Name',
          validStatus: currentUser?.subscription.status || 'undefined',
          rows: [
            {
              title: 'words',
              totalCount: wordsLimit,
              currentCount: res?.usage?.words,
              disabled: false,
            },
            {
              title: 'images',
              totalCount: imagesLimit,
              currentCount: res?.usage?.images,
              disabled: false,
            },
            {
              title: 'credits',
              totalCount: creditsLimit,
              currentCount:
                currentUser?.subscription.name === SubscriptionPlansEnum.FREE
                  ? res.credits
                  : res.usage.credits,
              disabled: false,
            },
          ],
        })
      }
    })
  }, [])

  const rowBlock = plan.rows.map((item, index) => {
    const {title, totalCount, currentCount, disabled} = item

    return (
      !disabled && (
        <div key={index} className={classes.plan__chart__row__item}>
          <div className={classes.plan__chart__row__item__progress}>
            <div
              className={classes.plan__chart__row__item__progress__bar}
              style={{width: (currentCount / totalCount) * 100 + '%'}}
            ></div>
          </div>
          <div className={classes.plan__chart__row__item__bottomBlock}>
            <span>
              {totalCount - currentCount}{' '}
              {title === 'credits' && plan.name === SubscriptionPlansEnum.FREE
                ? 'workflow credits (non-renewable in free plan)'
                : `${title} remaining in your plan this month`}
            </span>
            <span>
              {currentCount} of {totalCount} Used
            </span>
          </div>
        </div>
      )
    )
  })

  return (
    <div className={classes.plan}>
      <h3 className={classes.plan__title}>Current plan</h3>
      <div className={classes.plan__chart}>
        <div className={classes.plan__chart__topBlock}>
          <h3 className={classes.plan__chart__topBlock__title}>{plan.name}</h3>
          <p>
            {plan.name === SubscriptionPlansEnum.FREE
              ? `Your free plan is currently ${plan.validStatus}`
              : `You are on ${plan.name} plan which is ${plan.validStatus}`}
          </p>
        </div>
        <div className={classes.plan__chart__row}>{rowBlock}</div>
      </div>
      <div className={classes.plan__btnBlock}>
        <button className={`universalButton bg-dark`} onClick={() => {
          handleCancelSubscription()
        }} disabled={disableCancelBtn}>
          Cancel subscription
        </button>
        <Link to='/upgrade-plan'>
          <button className={`universalButton ${classes.plan__btnBlock__upgrade}`}>
            Upgrade plan
          </button>
        </Link>
      </div>
    </div>
  )
}

export default CurrentPlanBlock
