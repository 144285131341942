import type {FC} from 'react'
import {AuthInit} from '../../app/modules/auth'
import {Outlet} from 'react-router-dom'
import {IntercomProvider} from 'react-use-intercom'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID
  ? process.env.REACT_APP_INTERCOM_APP_ID
  : ''

interface AppProps {}

const App: FC<AppProps> = () => {
  return (
    <AuthInit>
      <IntercomProvider appId={INTERCOM_APP_ID} autoBoot>
        <Outlet />
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </IntercomProvider>
    </AuthInit>
  )
}

export default App
