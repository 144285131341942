import {FC, useEffect, useState} from 'react'
import classes from './HomePage.module.scss'
import QuickAccessArea from './QuickAccessArea'
import GraphicsArea from './GraphicsArea'
import RecentActivityArea from './RecentActivityArea'
import {useAuth} from '../../../app/modules/auth'
import {useGetCompanyMutation} from '../../../services/companyServiceApi'
import {LimitNotification} from '../universal-components/limit-notification/LimitNotification'
import {UniversalNotification} from '../universal-components/universal-notification/UniversalNotification'

const HomePage: FC = () => {
  const {currentUser} = useAuth()
  const [getCompany] = useGetCompanyMutation()
  const [company, setCompany] = useState<any>()
  const [notification, setNotification] = useState('')
  const getCompanyData = async () => {
    try {
      const companyData = await getCompany(currentUser?.company_name).unwrap()
      setCompany(companyData)
      await checkTrial(companyData.subscription)
    } catch (err) {
      return {err}
    }
  }

  const checkTrial = async (companySubscription: any) => {
    const timeleft = new Date(companySubscription.trial_end)

    if (companySubscription.name !== 'Free Plan') return
    if (new Date() > new Date(companySubscription.trial_end)) {
      setNotification('Your trial period is over')
      return
    }

    setNotification(
      `You are currently on a free trial that ends ${timeleft.getFullYear()}-${
        timeleft.getMonth() + 1
      }-${timeleft.getDate()}`
    )
  }

  useEffect(() => {
    getCompanyData()
    var cookie: any = document.cookie.match('(^|;)\\s*trakdesk_cid\\s*=\\s*([^;]+)')
    if (Array.isArray(cookie)) {
      try {
        var trakdeskCid = JSON.parse(cookie.pop())
        var cid = trakdeskCid['cid']
        document.querySelectorAll('a[href^="https://buy.stripe.com/"]').forEach(function (a: any) {
          var url = new URL(a.href)
          url.searchParams.set('client_reference_id', cid)
          a.href = url.href
        })
      } catch (e) {
        console.log(e)
      }
    }
  }, [])

  return (
    <div className={classes.homepage}>
      {notification.trim() !== '' ? (
        <UniversalNotification notificatonMessage={notification} upgrade={true} />
      ) : null}
      <h3 className={classes.homepage__title}>Welcome back, {currentUser?.first_name}!</h3>
      <GraphicsArea currentUser={currentUser} company={company} />
      <QuickAccessArea />
      <RecentActivityArea />
    </div>
  )
}

export default HomePage
